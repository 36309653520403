@mixin text_Roboto($size, $color) {
  font-family: Roboto;
  font-style: normal;

  font-size: $size;
  color: $color;
}

.popup-alert {
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  &__wrapper {
    box-sizing: border-box;
    padding: 15px;
    min-width: 320px;
    width: 320px;
    height: auto;
    background: linear-gradient(0deg, #86D900 -14.67%, #21C700 81.33%);
    position: fixed;
    z-index: 100;
    border-radius: 10px;
    left: calc(50% - 160px);
    top: 30%;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.6s cubic-bezier(0.47, 0, 0.75, 0.72), visibility 0s linear;

    &.active {
      visibility: visible;
      opacity: 1;
    }
  }

  & p, & span {
    color: #fff;
  }

  &__btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  &__logo{
    padding-bottom: 20px;
  }

  &__caption {
    padding-bottom: 10px;
    @include text_Roboto(18px, #fff);

    &:last-child{
      padding-bottom: 20px;
    }
  }

  &__upload {
    &-wrapper{
      text-align: center;
      padding-bottom: 20px;
      padding: 10px 0;
    }

    &-btn {
      width: 210px;
      margin: 0 auto 20px;

      &:last-child{
        margin: 0 auto;
      }
    }

    &-image{
      width: 100%;
      height: auto;
    }
  }

  &__text-close &__text {
    @include text_Roboto(16px, #fff);
    text-decoration-line: underline;
    cursor: pointer;
  }

  &__list {
    list-style: inside;
    width: 100%;
    color: #fff;
    text-align: left;
    max-height: 200px;
    overflow-y: auto;

    &-item {
      padding-bottom: 15px;
    }
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #fff;
    width: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

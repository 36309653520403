@import "_mixins";
@import "_utilities";
@import "_my-variables";
@import "_myGrid";
@import "header/_header";
@import "header/_logo";
@import "header/_btn-add-poster";
@import "header/_language-dropdown";
@import "header/_items";
@import "header/_adaptive-header";
@import "header/_search";
@import "_footer";
@import "offer";
@import "tablet-search";
@import "burger-menu";
@import "seo-block";
@import "_add-ad";
@import "_auth";
@import "best-offers/_best-offers";
@import "best-offers/_adaptive-best-offers";
@import "_classifieds-filter";
@import "_popup-upload-application";
@import "popup-warning";
@import "_search";
@import "_big-card-product";
@import "_popup-send-message";
@import "_404";
@import "filter-tabs";
@import "profile-view";
@import "profile-edit";
@import "_adaptive";


a {
  text-decoration: none; /* Отменяем подчеркивание у ссылки */
  &:visited {
    color: #000;
  }
}


/*-- Убираем стрелочки у input[type="number"] --*/
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*------------------------------------------------*/

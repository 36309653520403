//@import "my-variables";
/*-------------------------------------*/
.popup__overflow {
  display: none;
}

.popup__overflow.active {
  display: block;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: $popup__overflow_back;
}

.popup__wrapper-auth {
  display: none;
}

.popup__wrapper-auth.active {
  display: block;
  width: 400px;
  height: auto; /*480px;*/

  background: $popup__wrapper-auth_background;
  border-radius: 6px;

  z-index: 11;
  position: absolute;
  top: calc((100vh - 480px)/2);
  left: calc((100vw - 400px)/2);

  padding: 30px 40px;
  box-sizing: border-box;

  .popup__svg-close {
    position: absolute;
    float:right;

    top: 18px;
    right: 21px;

    width: 30px;
    height: 30px;
    border-radius: 50%;

    cursor: pointer;

    @extend %row-flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: $popup__wrapper-auth_active_hover_back;
    }
  }

  .auth__offer {
    margin: 15px -5px 0;

    & p {
      @include text_Roboto(13px, #000000);
      text-align: center;
      line-height: 20px;

      & a {
        color: #808080;
        text-decoration: underline;
        margin: 0;
      }
    }
  }

  .popup__popup-auth { display: none; }
  .popup__popup-auth.active {
    width: 100%;

    display: block;
    margin-top: 20px;

    .popup-auth__tabs {
      width: 100%;
      height: 34px;

      @extend %row-flex;
      justify-content: flex-start;

      .popup-auth__tab-sing-in,
      .popup-auth__tab-sing-up {
        cursor: pointer;
        width: 50%;
        height: 100%;

        @extend %column-flex;
        justify-content: center;
        align-items: center;

        p {
          @include text_Roboto(14px, $popup-auth__tabs_text-color);
          font-weight: normal;
          line-height: normal;
          text-align: center;
        }

        div {
          width: 100%;
          height: 4px;

          background: $popup-auth__tab-sing_back;

          margin-top: 7px;
        }

        &.active {
          cursor: default;
          p {
            font-weight: 900;
            font-size: 16px;
          }

          div {
            background: $popup-auth__tab-sing_active_back;
          }
        }
      }

      .popup-auth__tab-sing-in {
        div {
          border-radius: 5px 0px 0px 5px;
        }
      }

      .popup-auth__tab-sing-up {
        div {
          border-radius: 0px 5px 5px 0px;
        }
      }
    }

    .popup-auth__auth-block,
    .popup-auth__reg-block { display: none; }

    .popup-auth__auth-block.active {
      display: block;

      .auth-block__phone-block {

        margin-top: 40px;

        label {
          @include text_Roboto(14px, $main-color-2);
          font-weight: normal;
          line-height: 20px;
        }

        .phone-block__phone-input {
          width: 100%;
          height: 40px;
          display: flex;
          justify-content: flex-start;

          background: $main-color-1;
          border: 1px solid $phone-block__phone-input_border;
          box-sizing: border-box;
          border-radius: 5px;

          .phone-block__phone-code {
            width: 58px;
            height: 40px;

            border-right: 1px solid $phone-block__phone-code_border-color;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;

            display: flex;
            justify-content: center;
            align-items: center;

            @include text_Roboto(15px, $phone-block__phone-code_text-color);
            font-weight: normal;
            line-height: 23px;
          }

          input {
            width: calc(100% - 58px);

            background: $phone-block__phone-input_back;

            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;

            border: none;

            display: flex;
            justify-content: center;
            align-items: center;

            padding-left: 20px;
            box-sizing: border-box;

            &[placeholder] {
              padding-left: 20px;
              box-sizing: border-box;
            }
          }
        }
      }

      .auth-block__pass-block {
        width: 100%;
        height: auto;

        margin-top: 10px;

        label {
          @include text_Roboto(14px, $auth-block__pass-block_text-color);
          font-weight: normal;
          line-height: 20px;
        }

        .auth-block__pass-input {
          width: 100%;
          height: 100%;

          display: flex;
          justify-content: flex-start;

          background: $main-color-1;
          border: 1px solid $auth-block__pass-input_border-color;
          box-sizing: border-box;
          border-radius: 5px;

          .auth-block__nosee-pass-svg,
          .auth-block__see-pass-svg {
            display: none;
          }

          .auth-block__nosee-pass-svg.active,
          .auth-block__see-pass-svg.active {
            width: 58px;
            height: 40px;

            border-left: 1px solid $auth-block__svg_active_border-color;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;

            background: $main-color-1;
            box-sizing: border-box;

            display: flex;
            justify-content: center;
            align-items: center;
          }

          input {
            width: calc(100% - 58px);

            background: $auth-block__pass-input_back;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;

            //color: $auth-block__pass-input_text-color;
            border: none;

            display: flex;
            justify-content: center;
            align-items: center;

            padding-left: 20px;
            box-sizing: border-box;

            @include text_Roboto(15px, $auth-block__pass-input_text-color);
            font-weight: normal;
            line-height: 23px;

            &[placeholder] {
              padding-left: 20px;
              box-sizing: border-box;
            }
          }
        }
      }

      .auth-block__forgot-pass {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;

        p {
          @include text_Roboto(12px, $auth-block__forgot-pass_text-color);
          font-weight: bold;
          line-height: normal;
          cursor: pointer;

          text-align: right;
          letter-spacing: 0.03em;
          text-decoration-line: underline;
          text-transform: uppercase;
        }
      }

      .auth-block__btn-sing-in {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 40px;

        background: $auth-block__btn-sing-in_back;
        border: none;
        border-radius: 6px;

        margin-top: 20px;

        cursor: pointer;

        p {
          @include text_Roboto(13px, $main-color-1);

          font-weight: bold;
          line-height: normal;
          text-align: center;

          letter-spacing: 0.03em;
          text-transform: uppercase;
        }
      }

      .auth-block__other-way-sing {
        width: 100%;

        margin-top: 25px;

        p {
          @include text_Roboto(14px, $main-color-2);

          font-weight: normal;
          line-height: 20px;
          text-align: center;
        }

        .auth-block__wrapper-other-way-sing {
          display: flex;
          justify-content: flex-start;
          height: 28px;
        }
      }

    }

    .popup-auth__reg-block.active {
      display: block;

      .reg-block__step1,
      .reg-block__step2 { display: none; }

      .reg-block__step1.active,
      .reg-block__step2.active {
        display: block;
      }

      .reg-block__step1.active {
        .reg-block__phone-block {

          margin-top: 40px;

          label {
            @include text_Roboto(14px, $main-color-2);

            font-weight: normal;
            line-height: 20px;
          }

          .phone-block__phone-input {
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: flex-start;

            background: $phone-block__phone-input_back;
            border: 1px solid $phone-block__phone-input_border-color;
            box-sizing: border-box;
            border-radius: 5px;

            .phone-block__phone-code {
              width: 58px;
              height: 40px;

              border-right: 1px solid $phone-block__phone-code_border-color;
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;

              display: flex;
              justify-content: center;
              align-items: center;

              @include text_Roboto(15px, $phone-block__phone-code_text-color);

              font-weight: normal;
              line-height: 23px;
            }

            input {
              width: calc(100% - 58px);

              background: $phone-block__phone-input_input_back;
              box-sizing: border-box;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;

              border: none;

              display: flex;
              justify-content: center;
              align-items: center;

              padding-left: 20px;
              box-sizing: border-box;

              &[placeholder] {
                padding-left: 20px;
                box-sizing: border-box;
              }
            }
          }
        }

        .reg-block__sms-code-block {
          width: 100%;
          height: auto;

          display: flex;
          justify-content: flex-start;
          align-items: flex-end;

          margin-top: 10px;

          .sms-code-block__sms-code-input {
            margin-right: 30px;

            label {
              @include text_Roboto(14px, $main-color-2);
              font-weight: normal;
              line-height: 20px;
            }

            input {
              width: 97px;
              height: 40px;

              background: $sms-code-block__sms-code-input_input_back;
              border: 1px solid $sms-code-block__sms-code-input_input_border-color;
              box-sizing: border-box;
              border-radius: 5px;

              box-sizing: border-box;

              display: flex;
              justify-content: center;
              align-items: center;

              padding-left: 20px;

              @include text_Roboto(15px, $sms-code-block__sms-code-input_text-color);

              font-weight: normal;
              line-height: 23px;

              &[placeholder] {
                padding-left: 20px;
                box-sizing: border-box;
              }
            }
          }

          .sms-code-block__btn-get-sms {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 193px;
            height: 40px;

            background: $sms-code-block__btn-get-sms_back;
            border: none;
            border-radius: 6px;

            cursor: pointer;

            p {
              @include text_Roboto(13x, $main-color-1);
              font-weight: bold;
              line-height: normal;
              text-align: center;
              letter-spacing: 0.03em;
              text-transform: uppercase;
            }
          }
        }

        .reg-block__btn-next-step {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 100%;
          height: 40px;

          background: $reg-block__btn-next-step_back;
          border: none;
          border-radius: 6px;

          margin-top: 48px;

          cursor: pointer;

          p {
            @include text_Roboto(13px, $main-color-1);
            font-weight: bold;
            line-height: normal;
            text-align: center;
            letter-spacing: 0.03em;
            text-transform: uppercase;
          }
        }

        .reg-block__other-way-sing {
          width: 100%;

          margin-top: 36px;

          p {
            @include text_Roboto(14px, $main-color-2);
            font-weight: normal;
            line-height: 20px;
            text-align: center;
          }
        }
      }

      .reg-block__step2.active {
        .reg-block__name-block {
          margin-top: 40px;
          width: 100%;

          label {
            @include text_Roboto(14px, $main-color-2);
            font-weight: normal;
            line-height: 20px;
          }

          input {
            width: 100%;
            height: 40px;

            background: $reg-block__name-block_input_back;
            border: 1px solid $reg-block__name-block_input_border;
            box-sizing: border-box;
            border-radius: 5px;

            box-sizing: border-box;

            display: flex;
            justify-content: center;
            align-items: center;

            padding-left: 20px;

            @include text_Roboto(15px, $reg-block__name-block_input_text-color);
            font-weight: normal;
            line-height: 23px;

            &[placeholder] {
              padding-left: 20px;
              box-sizing: border-box;
            }
          }
        }

        .reg-block__pass-block,
        .reg-block__repeat-pass-block {
          width: 100%;
          height: auto;

          margin-top: 10px;

          label {
            @include text_Roboto(14px, $main-color-2);
            font-weight: normal;
            line-height: 20px;
          }
          input {
            width: calc(100% - 58px);

            background: $main-color-1;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;

            border: none;

            display: flex;
            justify-content: center;
            align-items: center;

            padding-left: 20px;
            box-sizing: border-box;

            @include text_Roboto(15px, $reg-block__repeat-pass-block_text-color);
            font-weight: normal;
            line-height: 23px;

            &[placeholder] {
              padding-left: 20px;
              box-sizing: border-box;
            }
          }

          .reg-block__pass-input,
          .reg-block__repeat-pass-input {
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: flex-start;

            background: $reg-block__repeat-pass-input_back;
            border: 1px solid $reg-block__repeat-pass-input_border;
            box-sizing: border-box;
            border-radius: 5px;

            .reg-block__see-reg-pass-svg,
            .reg-block__nosee-reg-pass-svg,
            .reg-block__see-repeat-pass-svg,
            .reg-block__nosee-repeat-pass-svg {
              display: none;
            }

            .reg-block__see-reg-pass-svg.active,
            .reg-block__nosee-reg-pass-svg.active,
            .reg-block__see-repeat-pass-svg.active,
            .reg-block__nosee-repeat-pass-svg.active {
              width: 58px;
              height: 40px;

              border-left: 1px solid $reg-block__svg_border-color;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;

              background: $main-color-1;
              box-sizing: border-box;

              display: flex;
              justify-content: center;
              align-items: center;
            }

          }
        }

        .reg-block__btn-sing-up {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 100%;
          height: 40px;

          background: $reg-block__btn-sing-up_back;
          border: none;
          border-radius: 6px;

          margin-top: 48px;

          cursor: pointer;

          p {
            @include text_Roboto(13px, $main-color-1);
            font-weight: bold;
            line-height: normal;
            text-align: center;
            letter-spacing: 0.03em;
            text-transform: uppercase;
          }
        }
      }

    }

    .other-way-sing__wrapper-other-way-sing {
      display: flex;
      justify-content: flex-start;
      height: auto; /*28px;*/

      .other-way-sing__facebook,
      .other-way-sing__google {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 50%;
        height: 100%;

        margin-top: 20px;

        cursor: pointer;

        div {
          margin-right: 10px;
          width: 22px;
          height: 22px;

          img {
            width: 22px;
            height: 22px;
          }
        }
      }

      .other-way-sing__facebook {
        border-right: 1px solid $main-color-3;
      }

      p {
        @include text_Roboto(14px, $other-way-sing__wrapper-other-way-sing_text-color);
        font-weight: normal;
        line-height: 21px;
      }
    }
  }

  .popup-auth__pass-reset { display: none; }
  .popup-auth__pass-reset.active {
    display: block;

    .pass-reset__step1,
    .pass-reset__step2 { display: none; }

    .pass-reset__step1.active,
    .pass-reset__step2.active {
      display: block;
    }

    .pass-reset__name-step {
      width: 100%;
      @extend %row-flex;
      justify-content: center;
      align-items: center;

      margin-top: 30px;

      p {
        @include text_Roboto(20x, $main-color-2);
        font-weight: 900;
        line-height: normal;
        text-align: center;
      }
    }

    .pass-reset__step1.active {
      .pass-reset__phone-block {

        margin-top: 40px;

        label {
          @include text_Roboto(14px, $main-color-2);
          font-weight: normal;
          line-height: 20px;
        }

        .phone-block__phone-input {
          width: 100%;
          height: 40px;
          display: flex;
          justify-content: flex-start;

          background: $phone-block__phone-input_back;
          border: 1px solid $phone-block__phone-input_border;
          box-sizing: border-box;
          border-radius: 5px;

          .phone-block__phone-code {
            width: 58px;
            height: 40px;

            border-right: 1px solid $phone-block__phone-code_border;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;

            display: flex;
            justify-content: center;
            align-items: center;

            @include text_Roboto(15px, $phone-block__phone-code_text-color);
            font-weight: normal;
            line-height: 23px;
          }

          input {
            width: calc(100% - 58px);

            background: $phone-block__phone-input_back;
            box-sizing: border-box;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;

            border: none;

            display: flex;
            justify-content: center;
            align-items: center;

            padding-left: 20px;
            box-sizing: border-box;

            &[placeholder] {
              padding-left: 20px;
              box-sizing: border-box;
            }
          }
        }
      }

      .pass-reset__sms-code-block {
        width: 100%;
        height: auto;

        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        margin-top: 11px;

        .sms-code-block__sms-code-input {
          margin-right: 30px;

          label {
            @include text_Roboto(14px, $main-color-2);
            font-weight: normal;
            line-height: 20px;
          }

          input {
            width: 97px;
            height: 40px;

            background: $sms-code-block__sms-code-input_back;
            border: 1px solid $sms-code-block__sms-code-input_border;
            box-sizing: border-box;
            border-radius: 5px;

            box-sizing: border-box;

            display: flex;
            justify-content: center;
            align-items: center;

            padding-left: 20px;

            @include text_Roboto(15px, $sms-code-block__sms-code-input_text-color);
            font-weight: normal;
            line-height: 23px;

            &[placeholder] {
              padding-left: 20px;
              box-sizing: border-box;
            }
          }
        }

        .sms-code-block__btn-get-sms {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 193px;
          height: 40px;

          background: $sms-code-block__btn-get-sms_back;
          border: none;
          border-radius: 6px;

          cursor: pointer;

          p {
            @include text_Roboto(13px, $main-color-1);
            font-weight: bold;
            line-height: normal;
            text-align: center;
            letter-spacing: 0.03em;
            text-transform: uppercase;
          }
        }
      }

      .pass-reset__btn-next-step {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 40px;

        background: $pass-reset__btn-next-step_back;
        border: none;
        border-radius: 6px;

        margin-top: 48px;

        cursor: pointer;

        p {
          @include text_Roboto(13px, $main-color-1);
          font-weight: bold;
          line-height: normal;
          text-align: center;
          letter-spacing: 0.03em;
          text-transform: uppercase;
        }
      }
    }

    .pass-reset__step2.active {

      .pass-reset__pass-block,
      .pass-reset__repeat-pass-block {
        width: 100%;
        height: auto;

        margin-top: 11px;

        label {
          @include text_Roboto(14px, $main-color-2);
          font-weight: normal;
          line-height: 20px;
        }

        input {
          width: calc(100% - 58px);

          background: $main-color-1;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;

          border: none;

          display: flex;
          justify-content: center;
          align-items: center;

          padding-left: 20px;
          box-sizing: border-box;

          @include text_Roboto(15px, $pass-reset__input_text-color);
          font-weight: normal;
          line-height: 23px;

          &[placeholder] {
            padding-left: 20px;
            box-sizing: border-box;
          }
        }

        .pass-reset__pass-input,
        .pass-reset__repeat-pass-input {
          width: 100%;
          height: 40px;

          display: flex;
          justify-content: flex-start;

          background: $pass-reset__back;
          border: 1px solid $pass-reset__border;
          box-sizing: border-box;
          border-radius: 5px;

          .pass-reset__see-pass-reset-svg,
          .pass-reset__nosee-pass-reset-svg,
          .pass-reset__see-repeat-pass-reset-svg,
          .pass-reset__nosee-repeat-pass-reset-svg {
            display: none;
          }

          .pass-reset__see-pass-reset-svg.active,
          .pass-reset__nosee-pass-reset-svg.active,
          .pass-reset__see-repeat-pass-reset-svg.active,
          .pass-reset__nosee-repeat-pass-reset-svg.active {
            width: 58px;
            height: 38px;

            border-left: 1px solid $pass-reset__svg_border;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;

            background: $pass-reset__svg_back;
            box-sizing: border-box;

            display: flex;
            justify-content: center;
            align-items: center;
          }

        }
      }

      .pass-reset__pass-block {
        margin-top: 41px;
      }

      .pass-reset__btn-save {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 40px;

        background: $pass-reset__btn-save_back;
        border: none;
        border-radius: 6px;

        margin-top: 48px;

        cursor: pointer;

        p {
          @include text_Roboto(13px, $main-color-1);
          font-weight: bold;
          line-height: normal;
          text-align: center;
          letter-spacing: 0.03em;
          text-transform: uppercase;
        }
      }
    }
  }

  .popup-auth__error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: auto;

    padding-top: 30px;

    h2 {
      @include text_Roboto(16px, $main-color-2);
      line-height: normal;
      font-weight: 900;
      text-align: center;
    }

    .error__btn-try-again {
      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;

      width: 100%;
      height: 40px;

      background: $error__btn-try-again_back;
      border: none;
      border-radius: 6px;
      margin-top: 20px;

      .error__btn-text {
        @include text_Roboto(13px, $main-color-1);
        font-weight: bold;
        line-height: normal;
        text-align: center;
        letter-spacing: 0.03em;
        text-transform: uppercase;
      }
    }
  }
}

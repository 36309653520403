//Fonts
@mixin font-face($alias, $name) {
  @font-face {
    font-family: $alias;
    src: url("../../fonts/${#name}.eot");
    src: url("../../fonts/${#name} + .eot?#iefix") format("embedded-opentype"),
    url("../fonts/${#name}.woff") format("woff"),
    url("../fonts/${#name}.woff2") format("woff2"),
    url("../fonts/${#name}.svg") format("svg"),
    url("../fonts/${#name}.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
  }
}

@mixin text_Roboto($size, $color) {
  font-family: Roboto;
  font-style: normal;

  font-size: $size;
  color: $color;
}

@mixin textOpenSans($size, $color) {
  font-family: Open Sans;
  font-style: normal;

  font-size: $size;
  color: $color;
}

@mixin text_OpenSans($size, $color) {
  font-family: Open Sans;
  font-style: normal;

  font-size: $size;
  color: $color;
}

// Placeholder color
@mixin placeholderColor($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &::placeholder {
    color: $color;
  }
}

@mixin padding($val_1, $val_2) {
  padding: $val_1 $val_2;
}

@mixin margin($val_1, $val_2) {
  margin: $val_1 $val_2;
}

//Clearfix
@mixin clearfix() {
  &:before, &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both
  }
}

//Box-sizing
@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model;
  -moz-box-sizing: $box-model;
  box-sizing: $box-model;
}

//Transition
@mixin transition($transition) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;

}

//Trasforms
@mixin transform($transform) {
  -moz-transform: $transform;
  -o-transform: $transform;
  -ms-transform: $transform;
  -webkit-transform: $transform;
  transform: $transform;
}

//Box-shadow
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  -ms-box-shadow: $shadow;
  box-shadow: $shadow;
}

//Border-radius
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;
}

//Flexbox module
@mixin flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

@mixin flex-item($number) {
  -webkit-box-flex: $number;
  -moz-box-flex: $number;
  -webkit-flex: $number;
  -ms-flex: $number;
  flex: $number;
}

@mixin align-content($align: stretch) {
  -webkit-align-content: $align;
  -ms-align-content: $align;
  align-content: $align;
}

@mixin align-items($align: stretch) {
  -webkit-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}

@mixin justify-content($justify: flex-start) {
  -webkit-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
}

@mixin flex-wrap($wrap: nowrap) {
  -webkit-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

//Animations
@mixin animation($arguments) {
  -webkit-animation: $arguments;
  -moz-animation: $arguments;
  animation: $arguments;
}






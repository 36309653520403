//@import "my-variables";
/*-- Content-block ---------------------------------------------------*/
.block-content{
    width: 100%;

    background: $block-content_background;

    .block-content__wrapper-header-poster {
        padding-top: 60px;
        width: 100%;
        max-width: 1146px;
        margin: 0 auto;

        .block-content__header-poster {
            @extend %row-flex;
            justify-content: flex-start;
            align-items: center;
            background: $block-content__header-poster_background;

            width: 100%;
            height: 48px;

            .header-poster__btn-go-back {
                @extend %row-flex;
                justify-content: center;
                align-items: center;

                width: 108px;
                height: 22px;

                border-right: 1px solid $header-poster__btn-go-back_border;

                .header-poster__svg-go-back {
                    padding-right: 5px;

                    @extend %row-flex;
                    justify-content: center;
                    align-items: center;
                }

                p {
                    @include text_Roboto( 12px, $header-poster__btn-go-back_text-color);
                    font-weight: bold;
                    line-height: normal;
                    letter-spacing: 0.03em;
                    text-transform: uppercase;
                }

                &:hover {
                  .header-poster__svg-go-back {
                    svg path {
                      fill: $header-poster__btn-go-back_text-color-hover;
                    }
                  }

                  p {
                    color: $header-poster__btn-go-back_text-color-hover;
                  }
                }
            }

            .header-poster__bread-crumbs {
                @extend %row-flex;
                justify-content: flex-start;
                align-items: center;

                width: calc(100% - 108px - 202px);
                padding-left: 30px;

                box-sizing: border-box;

                a {
                    @include text_Roboto( 12px, $header-poster__bread-crumbs_link-color);
                    font-weight: bold;
                    line-height: normal;

                    letter-spacing: 0.03em;
                    text-transform: uppercase;
                }

                svg {
                    padding-left: 5px;
                    padding-right: 5px;
                }

            }

            .header-poster__btn-add-favorites {
                width: 202px;
                height: 22px;

                border-left: 1px solid $header-poster__btn-add-favorites_border;

                @extend %row-flex;
                justify-content: center;
                align-items: center;

                p {
                    margin: 0;

                    @include text_Roboto( 12px, $header-poster__btn-add-favorites_text-color);
                    font-weight: bold;
                    line-height: normal;

                    letter-spacing: 0.03em;
                    text-transform: uppercase;

                    padding-right: 18px;
                }
            }
        }
    }

    .block-content__poster-block {
        @extend %row-flex;
        justify-content: flex-start;
        align-items: flex-start;

        padding: 30px;
        width: 100%;
        max-width: 1146px;
        margin: 0 auto;
        box-sizing: border-box;

        background: white;
        border-top: 1px solid $block-content__poster-block_border;

        /*--- Left part ---*/
        .poster-block__product-info {
            width: 65%;

            @extend %column-flex;
            justify-content: flex-start;
            align-items: flex-start;

            border-right: 1px solid $poster-block__product-info_border;

            padding-right: 30px;
            box-sizing: border-box;

            .poster-block__main-info-product {
                @extend %row-flex;
                justify-content: center;
                align-items: flex-start;

                .poster-block__image-poster {
                    display: none;
                }
                .poster-block__image-poster.active {
                    @extend %row-flex;
                    justify-content: center;
                    align-items: center;

                    width: 332px;
                    height: 301px;

                    background: $poster-block__image-poster_background;

                    img {
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                /*-- Slider --------------------------------------*/
                .poster-block__images-carousel {
                    display: none;
                }
                .poster-block__images-carousel.active {
                    display: block;
                    width: 332px;
                    height: auto;

                    .slider-images-product{
                        margin: 0 auto 1px;
                        overflow: hidden;
                        width: 332px;
                        height: 300px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .slick-list {
                            width: 332px;
                            height: 300px;

                            .slick-track {
                                height: 300px;

                                .slick-slide {
                                    @extend %row-flex;
                                    justify-content: center;
                                    align-items: center;

                                    img {
                                        min-height: 0;
                                        width: auto;
                                        max-width: 100%;
                                        height: auto;
                                        max-height: 100%;
                                    }
                                }
                            }
                        }
                    }

                    .slider-nav {
                        //width: 500px;
                        width: 332px;
                        height: 44px;
                        margin: auto;

                        margin-top: 16px;

                        .slick-list {
                            overflow: hidden;
                            margin: 0;
                            padding: 5px 50px;
                            background: $poster-block__images-carousel_sllick-list_background;
                            border-radius: 4px;
                            box-sizing: border-box;

                            height: 44px;

                            .slick-track {
                                height: auto;

                                .item.slick-slide {
                                    height: 34px;
                                    border: none;

                                    @extend %row-flex;
                                    justify-content: center;
                                    align-items: center;

                                    img {
                                        width: 34px;
                                        height: 34px;

                                        border: 1px solid $poster-block__images-carousel_slick-slide_img-border;
                                        box-sizing: border-box;
                                    }
                                }
                            }
                        }

                        .slick-arrow {
                            position: absolute;
                            top: 50%;
                            z-index: 50;
                            width: 34px;
                            height: 34px;
                            background: $poster-block__images-carousel_slick-slick-arrow-background;
                            border-radius: 4px;

                            margin-top: 0;
                        }

                        .slick-prev {
                            left: 5px;

                            background-image: url("/img/prev.png");
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: auto;
                        }

                        .slick-next {
                            right: 5px;

                            background-image: url("/img/next.png");
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: auto;
                        }

                        .slick-next:before,
                        .slick-prev:before {
                            display: none!important;
                        }

                        .item {
                            width: 34px;
                            height: 34px;

                            border: 1px solid $main-color-1;
                            box-sizing: border-box;

                            img {
                                width: 32px;
                                height: 32px;
                            }
                        }
                    }
                }
                /**************************************************/

                .poster-block__description-product {
                    padding-left: 30px;
                    box-sizing: border-box;

                    width: calc(100% - 332px);

                    .description-product__type {
                        background: #21C700;
                        border-radius: 5px;
                        box-sizing: border-box;
                        padding: 5px 15px;
                        display: inline-block;
                        margin-bottom: 10px;

                        &-text {
                            text-align: center;
                            text-transform: uppercase;
                            color: white;
                            font-weight: bold;
                            font-family: Open Sans;
                            letter-spacing: 0.5px;
                        }
                    }

                    .description-product__name-product {
                        @include text_Roboto( 24px, $main-color-2);
                        font-weight: bold;
                        line-height: normal;
                    }

                    .description-product__text-product {
                        @include text_Roboto( 15px, $description-product__text-product_text-color);
                        font-weight: normal;
                        line-height: 23px;

                        padding-top: 20px;
                    }

                    .description-product__characteristics-table {
                        @extend %column-flex;
                        justify-content: flex-start;
                        align-items: flex-start;

                        width: 100%;

                        padding: 30px 0 0 0;

                        .characteristics-table__row-characteristics-table {
                            @extend %row-flex;
                            justify-content: flex-start;
                            align-items: flex-start;

                            width: 100%;

                            .characteristics-table__column-name-field {
                                width: 50%;

                                p {
                                    @include text_Roboto( 14px, $characteristics-table__column-name-field_text-color);
                                    font-weight: normal;
                                    line-height: 21px;
                                }
                            }

                            .characteristics-table__column-value-field {
                                width: 50%;

                                p {
                                    @include text_Roboto( 14px, $characteristics-table__column-value-field_text-color);
                                    font-weight: normal;
                                    line-height: 21px;
                                }
                            }
                        }
                    }

                    .description-product__price-product {
                        @include text_Roboto( 24px, $main-color-2);
                        font-weight: bold;
                        line-height: normal;

                        padding: 30px 0 0 0;
                        margin: 0;

                        text-align: left;
                    }

                    .description-product__date-poster {
                        @include text_Roboto( 14px, $description-product__date-poster_text-color);
                        font-weight: normal;
                        line-height: 21px;

                        padding-top: 40px;
                    }
                }
            }

            .poster-block__secondary-info {
                width: 100%;

                @extend %row-flex;
                justify-content: flex-start;
                align-items: flex-start;

                flex-wrap: wrap;

                .poster-block__characteristics {
                    @extend %row-flex;
                    justify-content: flex-start;
                    align-items: center;

                    width: calc(50% - 15px);
                    height: 44px;

                    margin-top: 30px;
                    margin-right: 30px;
                    padding-left: 16px;
                    box-sizing: border-box;

                    background: $poster-block__characteristics_background;
                    border-radius: 4px;

                    position: relative;

                    &:nth-child(2n+2) {
                        margin-right: 0;
                    }

                    .characteristics__value-characteristic {
                        padding-left: 10px;

                        @include text_Roboto( 14px, $characteristics__value-characteristic_text-color);
                        font-weight: normal;
                        line-height: 21px;
                    }

                    .characteristics__info {
                        display: none;

                        width: 24px;
                        height: 24px;

                        background: $characteristics__info_background;
                        border-radius: 4px;

                        position: absolute;
                        top: 10px;
                        right: 10px;

                        @extend %row-flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

            }
        }

        /*--- Right part ---*/
        .poster-block__feedback-info {
            width: 35%;

            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            padding-left: 30px;
            box-sizing: border-box;

            .poster-block__info-about-publisher {
                width: 100%;
                height: 80px;
                border: 1px solid $poster-block__info-about-publisher_border;
                box-sizing: border-box;
                border-radius: 4px;

                @extend %row-flex;
                justify-content: flex-start;
                align-items: center;

                .info-about-publisher__img {
                    @extend %row-flex;
                    justify-content: center;
                    align-items: center;

                    padding: 7px;
                    box-sizing: border-box;

                    width: 80px;
                    height: 80px;
                    left: 894px;
                    top: 411px;

                    background: $info-about-publisher__img_background;
                    border-radius: 4px;

                    img{
                        max-width: 80px;
                        width: 100%;
                        max-height: 80px;
                        height: auto;
                    }
                }

                .info-about-publisher__name-publisher {
                    padding-left: 16px;
                    box-sizing: border-box;

                    .name-publisher__fio {
                        @include text_Roboto( 17px, $main-color-2);
                        font-weight: bold;
                        line-height: normal;
                    }

                    .name-publisher__name-company {
                        @include text_Roboto( 15px, $name-publisher__name-company_text-color);
                        font-weight: normal;
                        line-height: 23px;
                    }
                }
            }

            .poster-block__btn-send-letter-publisher {
                display: none;
                width: 100%;
                height: 40px;

                background: $poster-block__btn-send-letter-publisher_background;
                border-radius: 6px;

                margin: 30px 0 0 0;

                @extend %row-flex;
                justify-content: center;
                align-items: center;

                p {
                    @include text_Roboto( 13px, $poster-block__btn-send-letter-publisher_text-color);
                    font-weight: bold;
                    line-height: normal;

                    text-align: center;
                    letter-spacing: 0.03em;
                    text-transform: uppercase;
                }
            }

            .poster-block__contacts {

                margin: 30px 0 0 0;

                width: 100%;
                height: auto;

                background: $poster-block__contacts_background;
                border-radius: 6px;

                .contacts__drop-down-menu {
                    height: 30px;

                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;

                    @extend %row-flex;
                    justify-content: center;
                    align-items: center;

                    position: relative;

                    cursor: pointer;

                    .contacts__svg-down {
                        display: none;
                    }

                    .contacts__svg-down.active {
                        display: block;
                        position: absolute;

                        top: 6px;
                        right: 20px;
                    }

                    .contacts__svg-up {
                        display: none;
                    }
                    .contacts__svg-up.active {
                        display: block;
                        position: absolute;

                        top: 6px;
                        right: 20px;
                    }

                    .contacts__open-text,
                    .contacts__close-text {
                        display: none;
                    }

                    p,
                    .contacts__open-text.active,
                    .contacts__close-text.active {
                        display: block;

                        @include text_Roboto( 13px, $contacts__drop-down-menu_text-color);
                        font-weight: bold;
                        line-height: normal;

                        text-align: center;
                        letter-spacing: 0.03em;
                        text-transform: uppercase;
                    }

                }

                .contacts__contacts-info {
                    display: none;
                }

                .contacts__contacts-info.active {
                    display: block;

                    border-top: 1px solid $contacts__contacts-info_active_border;

                    .contcts__block-phones,
                    .contcts__block-mails {
                        @extend %row-flex;
                        justify-content: flex-start;
                        align-items: center;

                        padding: 16px;
                        box-sizing: border-box;
                    }

                    .contcts__block-phones a,
                    .contcts__block-mails a {
                        @include text_Roboto( 14px, $main-color-2);
                        font-weight: normal;
                        line-height: 21px;
                        font-size: 14px;
                    }

                    .contcts__block-phones .contcts__phones,
                    .contcts__block-mails .contcts__mails {
                        padding-left: 16px;
                    }
                }
            }

            .poster-block__address {
                width: 100%;
                height: 104px;

                background: $poster-block__address_background;
                border-radius: 4px;

                margin-top: 30px;

                @extend %row-flex;
                justify-content: flex-start;
                align-items: center;

                padding: 16px;
                box-sizing: border-box;

                .address__text-address {
                    padding-left: 16px;

                    p {
                        @include text_Roboto( 14px, $main-color-2);
                        font-weight: normal;
                        line-height: 21px;
                    }

                    .address__street {
                        padding-top: 16px;
                    }
                }
            }
        }
    }

    .block-content__wrapper-others-posters {
        width: 100%;
        @extend %column-flex;
        justify-content: flex-start;
        align-items: flex-start;

        width: 100%;
        max-width: 1146px;
        margin: 0 auto;
        box-sizing: border-box;

        margin-top: 40px;

        .block-content__header-others-posters {
            @extend %row-flex;
            width: 100%;
            height: 40px;

            .block-content__header-others-posters {
                @extend %row-flex;

                @include text_OpenSans(24px, $main-color-2);
                font-size: 24px;
                font-weight: 800;
                line-height: normal;
            }

            .block-content__show-all-posters {
                @extend %row-flex;
                justify-content: center;

                width: 300px;
                height: 40px;
                background: $block-content__show-all-posters_back;
                border-radius: 6px;

                padding: 0 15px;
                box-sizing: border-box;

                cursor: pointer;

                .show-all-posters__text {
                    @include text_Roboto(13px, $show-all-posters_text-color);
                    font-weight: bold;
                    line-height: 40px;
                    letter-spacing: 0.03em;
                    text-transform: uppercase;
                }

                .show-all-posters__svg-arrow {
                    margin-left: 10px;
                    svg {
                        padding: 12px 0;
                    }
                }
            }
        }

        .block-content__others-posters {
            @extend %row-flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;

            width: 100%;

            margin-top: 20px;

            .block-content__one-poster {
                width: calc((100% - 90px) / 4);
                height: auto; //287px;

                background: $block-content__one-poster_background;
                border-radius: 16px;

                margin-right: 30px;
                margin-bottom: 30px;

                &:nth-child(4n+4) {
                    margin-right: 0;
                }

                /*
                .one-poster__image {
                    @extend %row-flex;
                    justify-content: center;
                    align-items: center;

                    border-top-left-radius: 16px;
                    border-top-right-radius: 16px;

                    border-bottom: 1px solid  $one-poster__image_border;

                    width: 100%;
                    height: 178px;

                    img {
                        max-width: 100%;
                        width: auto;
                        max-height: 178px;
                        min-height: 0;
                        height: auto;
                    }
                }
                */

                .one-poster__image-responsive-box {
                    position: relative;
                    width: 100%;

                    border-top-left-radius: 16px;
                    border-top-right-radius: 16px;

                    border-bottom: 1px solid  $one-poster__image_border;

                    &::before {
                        content: "";
                        display: block;
                        padding-top: 100%; /* С помощью этого padding мы задаем высоту равную ширине блока */
                    }

                    .one-poster__image-content {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        //background: url(images/bg.jpg) no-repeat center center; /* Задаем фон, если требуется */
                        background-size: auto; /* Растягиваем фон под размеры блока, если требуется */
                        background-position: center;
                        background-repeat: no-repeat;

                        border-top-left-radius: 16px;
                        border-top-right-radius: 16px;
                    }
                }

                .one-poster__about-product {
                    width: 100%;
                    height: auto;

                    padding: 8px 16px 16px 16px;

                    box-sizing: border-box;

                    position: relative;

                    /*--- Fade text -----------*/
                    .one-poster__text-product {
                        width: 100%;
                        position: relative;
                        height: 70px;
                        overflow: hidden;

                        p {
                            @include text_Roboto( 14px, $main-color-2);
                            font-weight: normal;
                            line-height: 20px;

                            margin: 0;
                            overflow: hidden;

                            &:first-of-type {
                                text-align: left;
                            }
                        }

                        /*&:before,*/
                        &:after {
                            content: "";
                            display: inline-block;
                            position: absolute;
                            height: 90px;
                            width: 100%;
                            top: 0;
                            background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIvPjxzdG9wIG9mZnNldD0iMTIlIiBzdG9wLWNvbG9yPSIjZWVlZWVlIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZWVlZWVlIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
                            background-size: 100%;
                            background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #fff), color-stop(12%, #fff), color-stop(100%, rgba(238, 238, 238, 0)));
                            background-image: -moz-linear-gradient(#fff, #fff 12%, rgba(238, 238, 238, 0));
                            background-image: -webkit-linear-gradient(#fff, #fff 12%, rgba(238, 238, 238, 0));
                            background-image: linear-gradient(#fff, #fff 12%, rgba(238, 238, 238, 0));
                            pointer-events: none;
                        }

                        &:after {
                            top: auto;
                            bottom: 0;
                            background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIgc3RvcC1vcGFjaXR5PSIwLjAiLz48c3RvcCBvZmZzZXQ9Ijg4JSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
                            background-size: 100%;
                            background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(238, 238, 238, 0)), color-stop(88%, #fff), color-stop(100%, #fff));
                            background-image: -moz-linear-gradient(rgba(238, 238, 238, 0), #fff 88%, #fff);
                            background-image: -webkit-linear-gradient(rgba(238, 238, 238, 0), #fff 88%, #fff);
                            background-image: linear-gradient(rgba(238, 238, 238, 0), #fff 88%, #fff);
                        }
                    }
                    /*-------------------------*/

                    .one-poster__price-product {
                        @include text_OpenSans( 18px, $main-color-2);
                        font-weight: bold;
                        line-height: normal;
                    }

                    .one-poster__btn-add-like {
                        position: absolute;
                        bottom: 16px;
                        right: 16px;
                    }
                }
            }

        }

        .block-content__show-all-posters-xs {
          display: none;
        }
    }

    .poster-block__btn-send-letter-publisher-xs {
        display: none;
    }
}

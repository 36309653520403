//@import "my-variables";
/*-- Content-block ---------------------------------------------------*/
.block-content{
  width: 100%;

  background: $block-content_background;

  .block-content__wrapper-header-classifieds-filter {
    padding-top: 60px;
    width: 100%;
    max-width: 1146px;
    margin: 0 auto;

    .block-content__header-classifieds-filter {
      @extend %row-flex;
      justify-content: flex-start;
      align-items: center;
      background: $block-content__header-poster_background;

      width: 100%;
      height: 48px;

      .header-classifieds-filter__btn-go-back {
        @extend %row-flex;
        justify-content: center;
        align-items: center;

        width: 108px;
        height: 22px;
        color: $linkColor;

        border-right: 1px solid $header-poster__btn-go-back_border;

        & a {
          color: $linkColor;
        }

        & svg path {
          fill: $linkColor;
        }

        .header-classifieds-filter__svg-go-back {
          padding-right: 5px;

          @extend %row-flex;
          justify-content: center;
          align-items: center;
        }

        p {
          @include text_Roboto( 12px, $header-poster__btn-go-back_text-color);
          font-weight: bold;
          line-height: normal;
          letter-spacing: 0.03em;
          text-transform: uppercase;
        }

        &:hover {
          p {
            color: $header-poster__btn-go-back_text-color-hover;
          }
        }
      }

      .header-classifieds-filter__bread-crumbs {
        @extend %row-flex;
        justify-content: flex-start;
        align-items: center;

        width: calc(100% - 108px - 480px);
        padding-left: 30px;

        box-sizing: border-box;

        a {
          @include text_Roboto( 12px, $header-poster__bread-crumbs_link-color);
          font-weight: bold;
          line-height: normal;

          letter-spacing: 0.03em;
          text-transform: uppercase;
        }

        svg {
          padding-left: 5px;
          padding-right: 5px;
        }

      }

      .header-classifieds-filter__btns-classifieds-filter {
        @extend %row-flex;
        justify-content: flex-start;
        align-items: center;

        width: 480px;
        padding-left: 25px;

        box-sizing: border-box;

        border-left: 1px solid $header-classifieds-filter__btns-classifieds-filter_border;

        .header-classifieds-filter__btn-new-classifieds,
        .header-classifieds-filter__btn-lowest-price,
        .header-classifieds-filter__btn-highest-price {
          width: auto;
          height: 22px;
          padding: 0;

          @extend %row-flex;
          justify-content: center;
          align-items: center;

          p {
            margin: 0;

            @include text_Roboto( 12px, $header-classifieds-filter__btns-classifieds-filter_text-color);
            font-weight: bold;
            line-height: normal;

            letter-spacing: 0.03em;
            text-transform: uppercase;

            padding-right: 18px;
          }

          &:hover {
            cursor: pointer;

            p {
              color:  $header-classifieds-filter__btns-classifieds-filter_text-color-hover;
            }
          }
        }
      }
    }
  }

  .block-content__wrapper-classifieds-filter-and-results {
    @extend %row-flex;
    justify-content: flex-start;
    align-items: flex-start;

    padding-top: 26px;
    width: 100%;
    max-width: 1146px;
    margin: 0 auto;

    box-sizing: border-box;

    .block-content__wrapper-classifieds-filter {
      width: 30%;
      padding-right: 30px;
      padding-bottom: 30px;

      box-sizing: border-box;

      .block-content__filter-item-sale,
      .block-content__filter-item-purchase {
        height: 50px;

        border-top: 1px solid $block-content__filter-item_border-color;

        @extend %row-flex;
        justify-content: space-between;
        align-items: center;

        width: 100%;
      }

      .filter-item-sale__checkbox-sale,
      .filter-item-purchase__checkbox-purchase {
        position: absolute;
        z-index: -1;
        opacity: 0;
        margin: 10px 0 0 20px;
        & + label {
          position: relative;
          padding: 0;
          cursor: pointer;

          width: 100%;

          @include text_OpenSans( 16px, $main-color-2);
          font-weight: 800;
          line-height: normal;

          &:before,
          &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 18px;
            height: 18px;
            transition: .2s;

            background: $filter-item__checkbox_back;
            border: 1px solid $filter-item__checkbox_border;
            box-sizing: border-box;
            border-radius: 3px;
          }
        }

        &:checked + label:before,
        &:checked + label:after {
          background-image: url("/img/check.png");
          background-color: $filter-item__checkbox_checked_back;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 80%;

          border: 1px solid $filter-item__checkbox_border;
          box-sizing: border-box;
          border-radius: 3px;
        }

        //&:focus + label:before {
        //  box-shadow: inset 0 2px 3px rgba(0,0,0,.2), 0 0 0 3px rgba(255,255,0,.7);
        //}
      }

      .block-content__filter-item-subcategory,
      .block-content__filter-item-delivery,
      .block-content__filter-item-region {
        @extend %column-flex;
        justify-content: flex-start;
        align-items: flex-start;

        width: 100%;

        height: auto;
        padding: 0 0 16px 0;

        border-top: 1px solid $block-content__filter-item_border-color;

        position: relative;
      }

      .filter-item-subcategory__menu-subcategories,
      .filter-item-delivery__menu-delivery,
      .filter-item-region__menu-region {
        @extend %row-flex;
        justify-content: space-between;
        align-items: center;

        width: 100%;

        height: 50px;

        p {
          @include text_OpenSans(16px,$main-color-2);
          font-weight: 800;
          line-height: normal;
        }

        .block-content__svg-up,
        .block-content__svg-down { display: none;}

        .block-content__svg-up.active,
        .block-content__svg-down.active {
          @extend %row-flex;
          justify-content: center;
          align-items: center;

          width: 22px;
          height: 22px;

          &:hover {
            background: $main-color-1;
            border-radius: 100px;
          }
        }
      }

      .menu-subcategories__items-menu,
      .menu-delivery__items-menu,
      .menu-region__items-menu,
      .menu-subcategories__amount-of-ads-found,
      .menu-delivery__amount-of-ads-found,
      .menu-region__amount-of-ads-found { display: none;}

      .menu-subcategories__items-menu.active,
      .menu-delivery__items-menu.active,
      .menu-region__items-menu.active {
        @extend %column-flex;
        justify-content: flex-start;
        align-items: flex-start;

        width: 100%;

        .items-menu__one-item-menu {
          @extend %row-flex;
          justify-content: space-between;
          align-items: center;
          height: 30px;
          width: 100%;

          //.items-menu__name-item {
          //  @include text_Roboto( 15px, $items-menu__name-item_text-color);
          //  font-weight: normal;
          //  line-height: 19px;
          //}

          .items-menu__count-posters {
            @include text_Roboto( 13px, $items-menu__count-posters_text-color);
            font-weight: normal;
            line-height: 20px;

            padding-left: 16px;
          }


          .items-menu__name-item-menu {
            height: 30px;

            //border-top: 1px solid $block-content__filter-item_border-color;

            @extend %row-flex;
            justify-content: space-between;
            align-items: center;

            width: 100%;

            input {
              position: absolute;
              z-index: -1;
              opacity: 0;
              //margin: 10px 0 0 20px;

              & + label {
                position: relative;
                padding: 0;
                cursor: pointer;

                width: 100%;
                padding-left: 40px;
                box-sizing: border-box;

                @include text_Roboto( 15px, $items-menu__name-item_text-color);
                font-weight: normal;
                line-height: 19px;

                &:before,
                &:after {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 18px;
                  height: 18px;
                  transition: .2s;

                  background: $items-menu__name-item-menu_input_back;
                  border: 1px solid $items-menu__name-item-menu_input_border;
                  box-sizing: border-box;
                  border-radius: 3px;
                }
              }

              &:checked + label:before,
              &:checked + label:after {
                background-image: url("/img/check.png");
                background-color: $items-menu__name-item-menu_input_check_back;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 80%;

                border: 1px solid $items-menu__name-item-menu_input_check_border;
                box-sizing: border-box;
                border-radius: 3px;
              }

              //&:focus + label:before {
              //  box-shadow: inset 0 2px 3px rgba(0,0,0,.2), 0 0 0 3px rgba(255,255,0,.7);
              //}
            }
          }

        }
      }

      .menu-subcategories__amount-of-ads-found.active,
      .menu-delivery__amount-of-ads-found.active,
      .menu-region__amount-of-ads-found.active {
        @extend %row-flex;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;

        width: auto;
        height: auto;

        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border: 1px solid $main-color-5;
        border-radius: 6px;

        padding: 5px 16px;

        background: $main-color-1;

        position: absolute;

        top: 8px;
        left: 110px;

        z-index: 9;

        p {
          @include text_Roboto( 13px, $main-color-2);
          font-weight: normal;
          line-height: 20px;
        }

        .amount-of-ads-found__svg {
          @extend %row-flex;
          justify-content: center;
          align-items: center;

          top: 13px;
          left: -7px;

          position: absolute;
        }

        &:hover {
          cursor: pointer;
          p {
            text-decoration: underline;
          }
        }

      }


      .block-content__filter-item-value-added-tax {

        height: 50px;

        border-top: 1px solid $block-content__filter-item_border-color;

        @extend %row-flex;
        justify-content: space-between;
        align-items: center;

        width: 100%;

        .filter-item-value-added-tax__checkbox-sale {
          position: absolute;
          z-index: -1;
          opacity: 0;
          margin: 10px 0 0 20px;

          & + label {
            position: relative;
            padding: 0;
            cursor: pointer;

            width: 100%;

            @include text_OpenSans(16px, $main-color-2);
            font-weight: 800;
            line-height: normal;

            &:before {
              content: '';
              position: absolute;
              top: 0;
              right: 0;

              background: $filter-item-value-added-tax__checkbox-sale_back;
              box-shadow: inset 0 2px 3px rgba(0,0,0,.2);;
              transition: .2s;

              width: 31px;
              height: 14px;

              /* main_color */
              border: 1px solid $filter-item-value-added-tax__checkbox-sale_border;
              box-sizing: border-box;
              border-radius: 10px;
            }

            &:after {
              content: '';
              position: absolute;
              top: -1px;
              right: 15px;

              border-radius: 10px;
              transition: .2s;

              width: 16px;
              height: 16px;

              background: $filter-item-value-added-tax__checkbox-sale_back_after;
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
            }
          }

          &:checked + label:before {
            background: $filter-item-value-added-tax__checkbox-sale_checked_back_before;
          }

          &:checked + label:after {
            right: 0;
          }

          //&:focus + label:before {
          //  box-shadow: inset 0 2px 3px rgba(0,0,0,.2), 0 0 0 3px rgba(255,255,0,.7);
          //}
        }
      }

      .block-content__filter-item-delivery {

        .filter-item-delivery__menu-delivery {
          @extend %row-flex;
          justify-content: space-between;
          align-items: center;

          width: 100%;

          height: 50px;

          p {
            @include text_OpenSans(16px,$main-color-2);
            font-weight: 800;
            line-height: normal;
          }

          .menu-delivery__svg-up,
          .menu-delivery__svg-down { display: none;}

          .menu-delivery__svg-up.active,
          .menu-delivery__svg-down.active {
            @extend %row-flex;
            justify-content: center;
            align-items: center;

            width: 22px;
            height: 22px;

            &:hover {
              background: $main-color-1;
              border-radius: 100px;
            }
          }
        }
      }

      .block-content__wrapper-btn-filter-with-parametrs {
        width: 100%;
        height: auto;

        display: flex;
        justify-content: left;
        align-items: center;

        .block-content__btn-filter-with-parametrs {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 140px;
          height: 40px;

          background: $block-content__btn-filter-with-parametrs_back;
          border-radius: 6px;

          .block-content__btn-text {
            @include text_Roboto(13px, $main-color-1);
            font-weight: bold;
            text-align: center;
            letter-spacing: 0.03em;
            text-transform: uppercase;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .block-content__wrapper-filter-results {
      width: 70%;

      .block-content__posters {
        @extend %row-flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        box-sizing: border-box;

        .block-content__one-poster {
          width: calc((100% - 60px) / 3);
          height: auto; //287px;

          background: $block-content__one-poster_background;
          border-radius: 16px;

          margin-right: 30px;
          margin-bottom: 30px;

          &:nth-child(3n+3) {
            margin-right: 0;
          }

          /*
          .one-poster__image {
            @extend %row-flex;
            justify-content: center;
            align-items: center;

            border-top-left-radius: 16px;
            border-top-right-radius: 16px;

            border-bottom: 1px solid  $one-poster__image_border;

            width: 100%;
            height: 178px;

            img {
              max-width: 100%;
              width: auto;
              max-height: 178px;
              min-height: 0;
              height: auto;
            }
          }
          */

          .one-poster__image-responsive-box {
            position: relative;
            width: 100%;

            border-top-left-radius: 16px;
            border-top-right-radius: 16px;

            border-bottom: 1px solid  $one-poster__image_border;

            &::before {
              content: "";
              display: block;
              padding-top: 100%; /* С помощью этого padding мы задаем высоту равную ширине блока */
            }

            .one-poster__image-content {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              //background: url(images/bg.jpg) no-repeat center center; /* Задаем фон, если требуется */
              background-size: cover; /* Растягиваем фон под размеры блока, если требуется */
              background-position: center;
              background-repeat: no-repeat;

              border-top-left-radius: 16px;
              border-top-right-radius: 16px;
            }
          }

          .one-poster__about-product {
            width: 100%;
            height: auto;

            padding: 8px 16px 16px 16px;

            box-sizing: border-box;

            position: relative;

            /*--- Fade text -----------*/
            .one-poster__text-product {
              width: 100%;
              position: relative;
              height: 70px;
              overflow: hidden;

              p {
                @include text_Roboto( 14px, $main-color-2);
                font-weight: normal;
                line-height: 20px;

                margin: 0;

                overflow: hidden;

                &:first-of-type {
                  text-align: left;
                }
              }

              /*&:before,*/
              &:after {
                content: "";
                display: inline-block;
                position: absolute;
                height: 90px;
                width: 100%;
                top: 0;
                background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIvPjxzdG9wIG9mZnNldD0iMTIlIiBzdG9wLWNvbG9yPSIjZWVlZWVlIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZWVlZWVlIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
                background-size: 100%;
                background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #fff), color-stop(12%, #fff), color-stop(100%, rgba(238, 238, 238, 0)));
                background-image: -moz-linear-gradient(#fff, #fff 12%, rgba(238, 238, 238, 0));
                background-image: -webkit-linear-gradient(#fff, #fff 12%, rgba(238, 238, 238, 0));
                background-image: linear-gradient(#fff, #fff 12%, rgba(238, 238, 238, 0));
                pointer-events: none;
              }

              &:after {
                top: auto;
                bottom: 0;
                background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIgc3RvcC1vcGFjaXR5PSIwLjAiLz48c3RvcCBvZmZzZXQ9Ijg4JSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
                background-size: 100%;
                background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(238, 238, 238, 0)), color-stop(88%, #fff), color-stop(100%, #fff));
                background-image: -moz-linear-gradient(rgba(238, 238, 238, 0), #fff 88%, #fff);
                background-image: -webkit-linear-gradient(rgba(238, 238, 238, 0), #fff 88%, #fff);
                background-image: linear-gradient(rgba(238, 238, 238, 0), #fff 88%, #fff);
              }
            }
            /*-------------------------*/

            .one-poster__price-product {
              @include text_OpenSans( 18px, $main-color-2);
              font-weight: bold;
              line-height: normal;
            }

            .one-poster__btn-add-like {
              position: absolute;
              bottom: 16px;
              right: 16px;
            }
          }
        }
      }
    }
  }

  .pagination {
    width: 100%;

    @extend %row-flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 30px;

    li:first-child,
    li:last-child,
    li.active {
      width: 30px;
      height: 30px;
      background: $main-color-1;
      border: 1px solid $pagination_li-active_color-border;
      box-sizing: border-box;
      border-radius: 3px;
    }

    & li {
      @extend %row-flex;
      justify-content: center;
      align-items: center;

      width: 30px;
      height: 30px;
      background: none;
      border: 1px solid transparent;
      cursor: pointer;
      margin-right: 16px;

      & span,
      & a {
        @extend %link;

        @include text_Roboto( 13px, $main-color-2);

        font-weight: normal;
        line-height: 30px;
        flex-grow: 1;
        text-align: center;
      }

      &:hover {
        border: 1px solid $pagination_li-active_color-border;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.block-content__classifieds-filter-block-xs {
  display: none;
}
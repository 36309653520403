.popup__wrapper-popup-upload-gp-as {
  display: none;
}

.popup__wrapper-popup-upload-gp-as.active {
  @extend %row-flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 60px;

  background: $popup__wrapper-popup-upload-gp-as_active_back;

  z-index: 11;

  position: relative;

  .popup-upload-gp-as__svg-close {
    position: absolute;
    top: 18px;
    right: 30px;
  }

  .popup__popup-upload-gp-as {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
  }

  .popup-upload-gp-as__header-popup-upload-gp-as {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: auto;
    height: auto;

    .header-popup-upload-gp-as__logo {
      display: none;
    }

    .header-popup-upload-gp-as__text {
      margin-left: 20px;
    }
  }

  p {
    @include text_Roboto(18px, $popup__wrapper-popup-upload-gp-as_active_text-color);
    font-weight: bold;
    line-height: 30px;
  }

  .popup-upload-gp-as__btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: auto;
    height: auto;

    margin-left: 20px;

    .popup-upload-gp-as__btn-upload-googlePlay,
    .popup-upload-gp-as__btn-upload-appStore {
      border: none;

      cursor: pointer;

      display: flex;
      justify-content: center;
      align-items: center;

      width: 165.6px;
      height: 40px;

      background: $popup-upload-gp-as__btn-upload_back;
      border-radius: 8px;
    }

    .popup-upload-gp-as__btn-upload-googlePlay {
      background-image: url("/img/google-play-desktop.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .popup-upload-gp-as__btn-upload-appStore {
      margin-left: 20px;

      background-image: url("/img/app-store-desktop.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  .popup-upload-gp-as__btn-close {
    display: none;
  }
}
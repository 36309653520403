.block-offer {
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  
  &__content {
    background: #ffffff;
    max-width: 1000px;
    margin: 0 auto;
  }
}

.c3 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal
}

.c1 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal
}

.c0 {
  padding-top: 0pt;
  padding-bottom: 10px;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left
}

.c2 {
  padding-bottom: 15px;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: center
}

.c4 {
  background-color: #ffffff;
  padding: 72pt 72pt 72pt 72pt
}

.c5 {
  height: 11pt
}



.block-header {
  .block-header__user-menu {
    .block-header__user-menu-second-part {
      .user-menu_language-dropdown {
        position: relative;
        padding: 0 19px;

        .user-menu_language-dropdown-view {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          line-height: normal;
          font-size: 14px;
          color: #000000;
          display: flex;
          align-items: center;
          user-select: none;
          cursor: pointer;
          width: 63px;
          height: 20px;

          .user-menu_language-dropdown-arrow {
            width: 0;
            height: 0;
            border-left: 5.5px solid transparent;
            border-right: 5.5px solid transparent;
            border-top: 7px solid #C4C4C4;
            margin-left: 4px;
          }
        }

        .user-menu_language-dropdown-body {
          position: absolute;
          top: 20px;
          left: 2px;
          z-index: 1000;
          display: none;
          background: #ffffff;
          border-radius: 5px;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          line-height: normal;
          font-size: 14px;
          color: #000000;
          user-select: none;
          cursor: pointer;
          padding: 10px 10px 10px 0;

        }

        .user-menu__item-icon {
          margin-right: 4px;
        }
      }
    }
  }
}

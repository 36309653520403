@mixin clearfix() {
  &:before, &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both
  }
}

@mixin text_Roboto($size, $color) {
  font-family: Roboto;
  font-style: normal;

  font-size: $size;
  color: $color;
}

.seo-block {
  width: 100%;
  max-width: 1146px;
  height: auto;
  margin: 26px auto;
  box-sizing: border-box;
  padding-bottom: 20px;
  font-family: Roboto;
  color: #353535;
  @include clearfix();

  & &__text-wrapper {
    width: 100%;
    height: 96px;
    overflow: hidden;
  }

  & h2, & h3, & h4 {
    line-height: 20px;
    font-size: 16px;
    padding-bottom: 5px;
    font-family: Roboto;
    color: #999999;
  }
  //text


  & p {
    text-indent: 16px;
    text-align: justify;
    padding-bottom: 5px;
    line-height: 16px;
    font-family: Roboto;
    font-size: 14px;
  }

  & &__btn {

    &-text {
      @include text_Roboto(14px, $linkColor);

      &-expand {
        float: right;
        width: auto;
        height: auto;
        cursor: pointer;
      }
    }
  }

  &__best-offer-wrapper {
    @media screen and (max-width: 1146px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  & ul {
    list-style: disc inside;
    font-size: 14px;

    & li {
      padding-bottom: 5px;
      padding-left: 30px;
    }
  }

  & img {
    width: auto;
    height: auto;

    &-wrapper{
      padding: 10px;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }
}

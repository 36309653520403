.wrapper {
  min-width: 320px;
  @include clearfix();
  margin: 0 auto;
  position: relative;
  clear: both;
  height: 100%;

  main & {
    padding: 15px 20px;
    overflow: auto;
  }
}

.row {
  position: relative;
  margin: 0px -20px;
  display: flex;
  flex-wrap: wrap;
  @include clearfix();

  .Header & {
    height: 100%;
  }
}

@for $i from 1 through 12 {
  .cell-#{$i} {
    width: 8.33333333333333333333333% * $i;
    float: left;
    position: relative;
    padding: 0 20px;
    box-sizing: border-box;
    @include clearfix();
  }
}

.cell-none {
  display: none;
}

@each $type, $size in (ms: '(min-width: 320px) and (max-width: 374px)',
        mm: '(max-width: 424px)',
        ml: '(max-width: 767px)',
        tab: '(max-width: 1023px)',
        lap: '(max-width: 1439px)') {
  @for $i from 1 through 12 {
    @media #{$size}{
      .cell-#{$type}-#{$i} {
        width: 8.33333333333333333333333% * $i;
        float: left;
        position: relative;
        padding: 0 20px;
        box-sizing: border-box;
        @include clearfix();
      }
    }
  }

}

@each $type, $size in (ms: '(min-width: 320px) and (max-width: 374px)',
        mm: '(max-width: 424px)',
        ml: '(max-width: 767px)',
        tab: '(max-width: 1023px)',
        lap: '(max-width: 1439px)') {
  @media #{$size}{
    .cell-#{$type} {
      width: 100%;
      box-sizing: border-box;
    }
  }
}

.cell-sm-none {
  @media (max-width: 768px) {
    display: none;
  }
}




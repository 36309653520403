.filter-tabs {
  max-width: 1146px;
  margin: 0 auto;
  padding: 15px 0;
}

.filter-tabs__list {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  justify-items: flex-start;
  align-items: center;
}

.filter-tabs__list_subcategory {
  margin-bottom: 20px;
}

.filter-tabs__list_tablet {
  display: none;
}

.filter-tabs__list_tablet li.filter-tabs__item {
  float: left;
  width: 50%;
  min-width: initial;
  box-sizing: border-box;
  border-radius: initial;
}

.filter-tabs__list_tablet li.filter-tabs__item:first-child {
  border-right: 2px solid lightgrey;
}

@media screen and (max-width: 768px) {
  .filter-tabs__list_tablet {
    display: block !important;
  }
}

.filter-tabs__list[data-tab="sort"] {
  background: white;
  border-radius: 0 6px 6px 6px;
}

.filter-tabs__list[data-tab=type] > .filter-tabs__item {
  background-color: transparent;
}

.filter-tabs__list[data-tab=type] > .filter-tabs__item.active {
  background: white;
}

@media screen and (max-width: 768px) {
  .filter-tabs__list {
    display: none;
  }
}

.filter-tabs__item {
  transition: color 0.15s ease-in;
  height: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  min-width: 160px;
}

.filter-tabs__item-text {
  color: #808080;
}

.filter-tabs__item-link:hover .filter-tabs__item-text {
  color: #0075FF;
}

.filter-tabs__item.active .filter-tabs__item-text {
  color: #0075FF;
}

.filter-tabs__item_right-separator:after {
  content: '';
  position: absolute;
  right: 0;
  top: calc(0px + 10%);
  width: 1px;
  height: 80%;
  background: #EEE;
}

.filter-tabs__item-link {
  display: block;
  color: inherit;
  cursor: pointer;
  padding: 15px 20px;
}

.filter-tabs__item-link.active {
  cursor: default;
}

.filter-tabs__item-text {
  font-family: "Open Sans";
  text-transform: uppercase;
  font-weight: 800;
  font-size: 12px;
}

.filter-tabs__item[data-tab=sell], .filter-tabs__item[data-tab=buy] {
  border-radius: 6px 6px 0 0;
}

.filter-tabs__item[data-tab=sell] a, .filter-tabs__item[data-tab=buy] a {
  padding: 15px 25px;
}

.filter-tabs__item[data-tab=sell] span, .filter-tabs__item[data-tab=buy] span {
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
  .filter-tabs {
    display: none;
  }
}

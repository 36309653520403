@media screen and (max-width: 1024px) {
  .block-header {
    display: none;
  }

  /*--- Первая строка хедера ---------------------------------------*/
  .block-header-mobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    width: 100%;
    background: #F7F7F7;

    .block-header__wrapper-main-panel {
      display: block;
      height: 48px;
      width: 100%;

      .block-header__main-panel {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 100%;
        background: $block-header__main-panel_back;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
        padding: 0 15px;
        box-sizing: border-box;

        .main-panel__logo {
          width: calc(100% / 3);
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 0;
          box-sizing: border-box;

          img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          }
        }

        .user-menu__notifications {
          width: calc((100% / 3) / 2);
          height: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          position: relative;

          .user-menu__count-notifications {
            display: none;
          }

          .user-menu__count-notifications.active {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 15px;
            height: 15px;
            background: $user-menu__count-notifications_active_back;
            border-radius: 50%;
            @include text_Roboto(9px, $user-menu__count-notifications_active_text-color);
            font-weight: bold;
            position: absolute;
            top: 15px;
            right: 0;
          }

          .user-menu__svg-notification {
            position: absolute;
            top: calc(50% - 7px);
            right: 7px;
          }
        }

        .main-panel__btn-add-poster {
          width: calc(100% / 3);
          height: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .btn-add-poster__svg {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            padding: 10px;
            box-sizing: border-box;
          }
        }

        .main-panel__burger-icon,
        .main-panel__burger-close {
          display: none;
        }

        .main-panel__burger-icon.active,
        .main-panel__burger-close.active {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: calc(100% / 3);
        }
      }
    }

    /*--- Бургер-меню ---------------------------------------*/
    .burger__overflow,
    .main-panel__main-menu-burger {
      display: none;
    }

    .burger__overflow.active {
      display: block;
      width: 100%;
      height: 100%;
      position: fixed;
      top: 48px;
      left: 0;
      z-index: 10;
      background: $burger__overflow_back;
    }

    .main-panel__main-menu-burger.active {
      display: block;
      background: $main-panel__main-menu-burger_active_back;
      font-family: Roboto;
      font-size: 16px;
      overflow-y: auto;
      width: 60%;
      max-height: 60%;
      min-width: 270px;
      border-bottom-right-radius: 6px;
      box-shadow: 3px 3px 5px $main-panel__main-menu-burger_active_box-shadow;
      position: fixed;
      top: 48px;
      left: 0;
      z-index: 10;
      padding: 10px 20px;

      .block-header__user-menu {
        width: 100%;
        margin: 10px auto 0 auto;
        border-top: 1px solid $block-header__user-menu_border;
        padding: 15px;
        box-sizing: border-box;
        display: block;

        .user-menu__auth {
          @include text_Roboto(12px, $user-menu__text_color);
          font-weight: normal;
          line-height: 32px;
          margin-bottom: 0;
          height: 32px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .user-menu__svg-sign-in {
            width: 25px;
            height: auto;
            padding-right: 15px;

            svg {
              width: 100%;
              height: auto;
            }
          }

          h2 {
            @include text_Roboto(12px, $user-menu__text_color);
            font-weight: normal;
            line-height: normal;
          }
        }

        .user-menu__user {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .user-menu__svg {
            padding-right: 15px;
          }
        }

        .user-menu__link-menu {
          border: none;
          background: none;
        }

        .user-menu__favorites,
        .user-menu__menu,
        .user-menu__notifications {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          width: auto;
          height: 32px;
        }

        .user-menu__favorites p,
        .user-menu__menu a {
          @include text_Roboto(12px, $user-menu__text_color);
          font-weight: normal;
          line-height: normal;
        }


        .user-menu__favorites .user-menu__svg-favorites,
        .user-menu__menu .user-menu_item-menu .user-menu__link-menu .user-menu__svg-down {
          padding-right: 15px;
        }

        .user-menu__favorites .user-menu__svg-favorites svg {
          width: 16px;
          height: auto;
        }

        .user-menu__menu a {
          text-decoration: none;
          cursor: pointer;
          width: 100%;
        }
      }
    }

    /*--- Вторая строка хедера (поиск)---------------------------------------*/
    .block-header__wrapper-search-categories-panel {
      width: 100%;
      background: $block-header__wrapper-search-categories-panel_back;
    }

    .block-header__block-search-panel {
      height: auto;
    }

    ul.block-search-panel__menu-city-xs {
      display: none;
    }

    // Выпадющее меню тип объявлений (продажа ии покупка)
    ul.block-search-panel__menu-type-classifieds {
      width: 100%;
      height: auto;
      background: $block-search-panel__menu-category_background;
      border: 1px solid $block-search-panel__menu-category_border;
      box-sizing: border-box;
      list-style: none;

      .menu-type-classifieds__item-menu {

        .menu-type-classifieds__wrapper-link-menu {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 40px;

          .menu-type-classifieds__link-menu {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: calc(100% - 40px);
            height: 40px;
            padding: 0 16px;
            box-sizing: border-box;
            //border: 1px solid $block-search-panel__menu-category_border;
            box-sizing: border-box;
            @include text_Roboto(15px, $main-color-2);
            font-weight: normal;
            line-height: 23px;
          }

          .menu-type-classifieds__svg {
            display: none;
          }

          .menu-type-classifieds__svg.active {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            cursor: pointer;
          }
        }
      }

      display: block;
      margin: 0;
      padding: 0;
      list-style: none;

      &:after {
        display: block;
        content: ' ';
        clear: both;
        float: none;
      }

      & > li {
        width: 100%;
        float: left;
        position: relative;

        & > a {
          width: 100%;
          display: block;
          color: $block-search-panel__menu-type-classifieds_text-color;
          background-color: $block-search-panel__menu-category_background;
          text-decoration: none;
          padding-left: 10px;
          height: 30px;
          box-sizing: border-box;
          border: none;
          text-decoration: none;
          justify-content: left;
          @include text_Roboto(15px, $main-color-2);
          font-weight: normal;
          line-height: 30px;

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      ul.menu-type-classifieds__submenu {
        display: none;
        border-bottom: 1px solid $block-search-panel__menu-category_border;
        border-top: 1px solid $block-search-panel__menu-category_border;
        box-sizing: border-box;

        & > li {
          display: block;
          width: 100%;
          padding: 10px 0;
          box-sizing: border-box;

          & > a {
            width: 100%;
            display: block;
            color: black;
            background-color: $block-search-panel__menu-category_background;
            text-decoration: none;
            padding-left: 10px;
            height: 30px;
            box-sizing: border-box;
            border: none;
            text-decoration: none;
            justify-content: left;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    //--- Выпадющее меню ---------------------------------
    .block-header__block-search-panel {
      ul.block-search-panel__menu-city {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
        height: 40px;

        & > li {
          float: left;
          position: relative;

          & > div {
            // display: block;
            // padding: 10px;
            // @extend %row-flex;
            display: flex;
            justify-content: space-around;
            align-items: center;
            background-color: $block-search-panel__menu-city_background;
            text-decoration: none;
            height: 40px;
            width: 217px;
            border: 1px solid $block-search-panel__menu-city_border;
            box-sizing: border-box;

            @include text_Roboto(15px, $main-color-2);
            font-weight: normal;
            line-height: 23px;

            &:hover {
              // background-color: grey;
              cursor: pointer;
            }
          }

          &:hover {
            ul.menu-city__submenu {
              //display: none;
              position: absolute;
              //width: 120px;
              //top: 25px;
              left: 0;
              background-color: $menu-city__submenu_back;
              //border-radius: 6px;
              box-shadow: 5px 10px 20px $menu-city__submenu_box-shadow;
              // border: 1px solid gray;
              display: block;
              padding: 0;
              top: 40px;
              border-radius: 0;
              width: 217px;
              z-index: 10;

              & > li {
                display: block;
                padding: 10px;
                width: 217px;
                box-sizing: border-box;

                & > a {
                  display: block;
                  // padding: 10px;
                  //color: black;
                  background-color: $menu-city__submenu_back;
                  //text-decoration: none;
                  padding-left: 10px;
                  height: 30px;
                  box-sizing: border-box;

                  @include text_Roboto(15px, $main-color-2);
                  font-weight: normal;
                  line-height: 23px;
                  text-decoration: none;

                  &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }

        ul.menu-city__submenu {
          display: none;
        }

        ul.menu-city__submenu {
          height: 250px;
          overflow: auto;
        }
      }

      ul:after {
        display: block;
        content: ' ';
        clear: both;
        float: none;
      }
    }

    //----------------------------------------------------
    //конец выпадающего меню

    .block-search-panel__search-input-block {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      max-width: 100%;
      height: 59px;
      background: $block-header__search-panel_background;
      border-radius: 0;
      position: relative;
      box-sizing: border-box;
      padding: 0 25px 0 15px;

      .block-search-panel__input-block {
        display: block;
        height: 40px;
        box-sizing: border-box;
        width: calc(100% - 52px - 217px);
        border: 1px solid $block-header__wrapper-search-panel_border;
        border-right: none;
        box-sizing: border-box;

        &[placeholder] {
          @include text_Roboto(15px, $block-search-panel__input-block_text-color);
          font-weight: normal;
          line-height: 23px;
          padding-left: 20px;
          padding-right: 60px;
          box-sizing: border-box;
          width: calc(100% - 52px - 217px);
        }
      }

      .block-search-panel__btn-search {
        //@extend %row-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 52px;
        height: 40px;
        background: $block-search-panel__btn-search_background;
        border: 1px solid $block-search-panel__btn-search_border;
        box-sizing: border-box;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        top: 16px;
        right: 16px;
      }
    }

    .block-header__wrapper-categories-panel {
      width: calc(100% - 32px);
      max-width: 1146px;
      height: 80px;
      background: $block-header__wrapper-categories-panel_background;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      padding: 0 30px;
      box-sizing: border-box;
      margin: 0 25px 0 15px;

      .menu-category__menu-category-svg,
      .menu-category__menu-category-svg.active {
        display: none;
      }

      .block-header__categories-panel {
        //@extend %row-flex;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
          //@extend %row-flex;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          height: 80px;

          div {
            padding-left: 10px;
            padding-right: 10px;
            box-sizing: border-box;
            //@extend %row-flex;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .categories-panel__category-count {
              @include text_Roboto(13px, $categories-panel__category-count_text-color);
              font-weight: normal;
              line-height: normal;
              margin: 0;
              padding-left: 15px;
            }

            .categories-panel__category-name {
              @include text_OpenSans(16px, $main-color-2);
              font-weight: 800;
              line-height: normal;
              margin: 0;
            }
          }
        }

        //--- Выпадющее меню ---------------------------------
        ul {
          display: block;
          margin: 0;
          padding: 0;
          list-style: none;

          &:after {
            display: block;
            content: ' ';
            clear: both;
            float: none;
          }

          &.categories-panel__menu-category > li {
            float: left;
            position: relative;

            //& > a.menu-category__link-menu,
            div.menu-category__link-menu {
              //@extend %row-flex;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              height: 80px;
              // padding: 10px;
              color: black;
              background-color: $block-header__user-menu__menu_background;
              text-decoration: none;

              a.menu-category__category-text {
                padding-left: 10px;
                padding-right: 10px;
                box-sizing: border-box;
                //@extend %row-flex;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                .menu-category__category-count {
                  @include text_Roboto(13px, $categories-panel__category-count_text-color);
                  font-weight: normal;
                  line-height: 20px;
                  margin: 0;
                  padding-left: 15px;
                }

                .menu-category__category-name {
                  @include text_OpenSans(16px, $main-color-2);
                  font-weight: 800;
                  line-height: normal;
                  margin: 0;
                }
              }

              &:hover {
                // background-color: grey;
                cursor: pointer;
              }
            }
          }

          &.menu-category__submenu {
            display: none;
            position: absolute;
            width: 100%;
            top: 80px;
            left: 0;
            background-color: $block-header__user-menu__submenu_background;
            border-radius: 6px;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
            //border: 1px solid $block-header__user-menu__borders-color;

            & > li {
              display: block;

              & > a.menu-category__link-submenu {
                //@extend %row-flex;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px;
                color: black;
                background-color: $block-header__user-menu__submenu_background;
                text-decoration: none;
                height: auto; //30px;
                box-sizing: border-box;
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;

                .menu-category__name-subcategory {
                  @include text_Roboto(15px, $menu-category__name-subcategory_text-color);
                  font-weight: normal;
                  line-height: 19px;
                }

                .menu-category__count-items-subcategory {
                  @include text_Roboto(13px, $menu-category__count-items-subcategory_text-color);
                  font-weight: normal;
                  line-height: 20px;
                }

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }

          &.categories-panel__menu-category > li:hover > ul.menu-category__submenu {
            display: block;
            z-index: 10 !important;
          }
        }

        //----------------------------------------------------
      }
    }

    .block-header__wrapper-btn-choose-categories,
    .block-header__wrapper-categories-panel-xs {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .block-header__wrapper-search-categories-panel .block-header__search-panel {
    padding: 0;
    top: 0;
  }

  .block-header,
  .block-header-mobile ul.block-search-panel__menu-type-classifieds,
  .block-header-mobile ul.block-search-panel__menu-city,
  .block-header-mobile .block-header__wrapper-categories-panel {
    display: none !important;
  }

  .block-header-mobile {

    .block-search-panel__search-input-block {
      background: $block-search-panel__search-input-block_back;
      border-top: 1px solid $block-search-panel__search-input-block_border-color;
      .block-search-panel__input-block {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      .block-search-panel__input-block[placeholder] {
        padding-right: 5px;
        width: calc(100% - 52px);
      }
    }

    .block-header__wrapper-btn-choose-categories {
      display: block;
      width: 100%;
      height: 48px;

      .block-header__btn-choose-categories {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0 16px;
        box-sizing: border-box;
        background: $block-header__btn-choose-categories_back;
        border: none;
        cursor: pointer;

        .btn-choose-categories__svg {
          display: flex;
          justify-content: center;
          align-items: center;
          width: auto;
          height: 100%;
        }

        .btn-choose-categories__text {
          @include text_OpenSans(16px, $btn-choose-categories__text_color);
          font-weight: 800;
          line-height: normal;
        }
      }
    }
  }

  .block-header__wrapper-categories-panel-xs {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    background: $block-header__wrapper-categories-panel-xs_back;
    z-index: 10;


    .wrapper-categories-panel-xs__btn-back {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 48px;
      padding: 0 16px;
      box-sizing: border-box;
      cursor: pointer;

      .btn-back__svg {
        padding: 0 5px 5px 0;
      }

      .btn-back__text {
        @include text_Roboto(12px, $btn-back__text_color);
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0.03em;
        text-transform: uppercase;
      }
    }

    .wrapper-categories-panel-xs__name-block {
      width: 100%;
      height: 48px;
      padding: 16px;
      box-sizing: border-box;

      .name-block__text {
        @include text_OpenSans(16px, $wrapper-categories-panel-xs__name-block_text-color);
        font-weight: 800;
        line-height: normal;
        text-align: center;
      }
    }

    .block-header__categories-panel {
      //@extend %row-flex;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-top: 22px;
      width: 100%;
      padding: 0;
      box-sizing: border-box;

      .categories-panel__menu-category {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;
        background: $categories-panel__menu-category_back;
        box-shadow: none;
        border: none;
        border-radius: 6px;

        & > .menu-category__item-menu {
          float: left;
          width: 100%;

          .menu-category__wrapper-link-menu {
            display: flex;
            justify-content: space-between;
            height: 48px;
            width: 100%;

            div.menu-category__link-menu {
              //@extend %row-flex;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              width: 100%;
              height: 48px;
              text-decoration: none;
              border-radius: 6px;
              padding: 0 16px;
              box-sizing: border-box;

              a.menu-category__category-text {
                padding: 0;
                box-sizing: border-box;
                //@extend %row-flex;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                height: auto;
                width: calc(100% - 48px);

                .menu-category__category-count {
                  @include text_Roboto(13px, $categories-panel__category-count_text-color);
                  font-weight: normal;
                  line-height: 20px;
                  margin: 0;
                  padding-left: 0;
                }

                .menu-category__category-name {
                  @include text_OpenSans(16px, $menu-category__category-name_text-color);
                  font-weight: 800;
                  line-height: normal;
                  margin: 0;
                }
              }

              .menu-category__svg-icon-category {
                width: 48px;
              }

              &:hover {
                cursor: pointer;
              }
            }

            .menu-category__menu-category-svg {
              display: none;
            }

            .menu-category__menu-category-svg.active {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 48px;
              height: 48px;
            }
          }
        }

        .menu-category__submenu {
          display: block;
          position: static;
          width: 100%;
          //top: 48px;
          //left: 0;
          background-color: $block-header__user-menu__submenu_background;
          border-radius: 6px;
          box-shadow: none;
          border-bottom: 2px solid $main-color-3;

          & > li {
            display: block;

            & > a.menu-category__link-submenu {
              //@extend %row-flex;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 16px;
              background-color: $block-header__user-menu__submenu_background;
              text-decoration: none;
              height: 48px;
              box-sizing: border-box;
              //border-bottom-left-radius: 6px;
              //border-bottom-right-radius: 6px;

              .menu-category__name-subcategory {
                @include text_Roboto(15px, $menu-category__name-subcategory_text-color);
                font-weight: normal;
                line-height: 19px;
              }

              .menu-category__count-items-subcategory {
                @include text_Roboto(13px, $menu-category__count-items-subcategory_text-color);
                font-weight: normal;
                line-height: 20px;
              }

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

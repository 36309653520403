.filter-tabs {
  max-width: 1146px;
  margin: 0 auto;
  padding: 15px 0;

  &__list {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50px;
    justify-items: flex-start;
    align-items: center;

    &_subcategory {
      margin-bottom: 20px;
    }

    &_tablet {
      display: none;

      & li.filter-tabs__item {
        float: left;
        width: 50%;
        min-width: initial;
        box-sizing: border-box;
        border-radius: initial;

        &:first-child {
          border-right: 2px solid lightgrey;
        }
      }

      @media screen and (max-width: 768px) {
        display: block!important;
      }
    }

    &[data-tab="sort"] {
      background: white;
      border-radius: 0 6px 6px 6px;
    }

    &[data-tab=type] > .filter-tabs__item {
      background-color: transparent;

      &.active {
        background: white;
      }
    }


    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &__item {
    transition: color 0.15s ease-in;
    height: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    min-width: 160px;

    &-text {
      color: #808080;

      .filter-tabs__item-link:hover & {
        color: #0075FF;
      }
    }

    &.active .filter-tabs__item-text {
      color: #0075FF;
    }

    &_right-separator:after {
      content: '';
      position: absolute;
      right: 0;
      top: calc(0px + 10%);
      width: 1px;
      height: 80%;
      background: #EEE;
    }

    &-link {
      display: block;
      color: inherit;
      cursor: pointer;
      padding: 15px 20px;

      &.active {
        cursor: default;
      }
    }

    &-text {
      font-family: "Open Sans";
      text-transform: uppercase;
      font-weight: 800;
      font-size: 12px;
    }

    &[data-tab=sell], &[data-tab=buy] {
      border-radius: 6px 6px 0 0;

      & a {
        padding: 15px 25px;
      }

      & span {
        font-size: 14px;
        text-align: center;
        white-space: nowrap;
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

#imgs .img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background: #FDFDFD;
  border: 1px solid #D2D8DE;
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;

  margin-right: 30px;

  &:last-of-type {
    margin-right: 0;
  }

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;

    border-radius: 6px;
  }
}


.one-poster__btn-add-like,
.block-header-mobile .main-panel__main-menu-burger.active .block-header__user-menu .user-menu__favorites,
.block-header-mobile .block-header__wrapper-main-panel .block-header__main-panel .user-menu__notifications {
  display: none !important;
}


/*---------------------------------------------------*/
/*--- add-ad.scss ---*/
@media screen and (max-width: 1024px) {
  .block-content__add-ad-wrapper {
    padding: 0 16px 52px 16px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 768px) {
  .block-content__add-ad-wrapper {
    padding: 0;

    .block-content__add-ad-block {
      padding: 25px 0;
      box-sizing: border-box;

      .add-ad-block__header-text {
        font-size: 16px;
      }

      .add-ad-block__add-buy-ad-form,
      .add-ad-block__add-sell-ad-form {
        width: 100%;
      }

      .add-ad-block__one-field {
        flex-direction: column;
        margin-top: 16px;
        padding: 0 16px;
        box-sizing: border-box;

        .one-field__text-field,
        .one-field__value-field {
          width: 100%;
        }

        .one-field__text-field {
          height: auto;

          p {
            line-height: 23px;
          }
        }

        .one-field__value-field {
          input {
            width: 100%;

            &[placeholder] {
              width: 100%;
              padding: 0 10px;
            }
          }

          .menu-categories__items-menu.active,
          .menu-categories__items-menu_1.active,
          .menu-categories__items-menu_2.active,
          .menu-payment-method__items-menu.active,
          .menu-payment-method__items-menu1.active,
          .menu-currency__items-menu.active,
          .menu-weight-units__items-menu.active,
          .menu-weight-units__items-menu1.active,
          .menu-region__items-menu.active,
          .menu-area__items-menu.active,
          .menu-city__items-menu.active,
          .menu-organic__items-menu.active,
          .menu-cooled__items-menu.active,
          .menu-presence-gap__items-menu.active {
            border: none;
            border-top: 1px solid $one-field__value-field_menu_border;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;
          }


          .one-field__value-field_price-block .one-field__secondary-text,
          .one-field__quantity .one-field__secondary-text,
          .one-field__min-quantity .one-field__secondary-text {
            display: none;
          }

          .one-field__value-field_price-block input,
          .one-field__quantity input,
          .one-field__min-quantity input {
            width: 130px;
          }

          .one-field__menu-weight-units,
          .one-field__menu-weight-units2,
          .one-field__menu-currency {
            width: 67px;
          }

          .one-field__menu-categories,
          .one-field__menu-categories_1,
          .one-field__menu-categories_2,
          .one-field__menu-payment-method,
          .one-field__menu-payment-method1,
          .one-field__menu-currency,
          .one-field__menu-weight-units,
          .one-field__menu-weight-units1,
          .one-field__menu-weight-units2,
          .one-field__menu-region,
          .one-field__menu-area,
          .one-field__menu-region1,
          .one-field__menu-city,
          .one-field__menu-organic,
          .one-field__menu-cooled,
          .one-field__menu-presence-gap,
          .menu-categories__items-menu.active,
          .menu-categories__items-menu_1.active,
          .menu-categories__items-menu_2.active,
          .menu-payment-method__items-menu.active,
          .menu-payment-method__items-menu1.active,
          .menu-currency__items-menu.active,
          .menu-weight-units__items-menu.active,
          .menu-weight-units__items-menu1.active,
          .menu-region__items-menu.active,
          .menu-city__items-menu.active,
          .menu-area__items-menu.active,
          .menu-organic__items-menu.active,
          .menu-cooled__items-menu.active,
          .menu-presence-gap__items-menu.active {
            padding: 0 10px;
          }

          .one-field__value-field_price-block .one-field__drop-down-menu,
          .one-field__quantity .one-field__drop-down-menu,
          .one-field__min-quantity .one-field__drop-down-menu {
            width: 67px;
          }

          .one-field__payments-form {
            width: 220px;
          }

          .one-field__photos-ad {
            justify-content: flex-start;
            flex-wrap: wrap;

            .one-field__add-photos {
              margin-bottom: 20px;
              margin-right: 20px;

              width: 80px;
              height: 80px;

              &:nth-child(3n+3) {
                margin-right: 0;
              }

              .add-photos__btn-add-photo.active,
              .one-field__image.active {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }

      .add-ad-block__btn-next-add-ad {
        margin-top: 30px;
        width: 100%;
        border-radius: 0;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .popup__wrapper-add-ad.active {
    width: 288px !important;
    height: 300px;

    left: calc((100vw - 288px) / 2);
    top: calc((100vh - 300px) / 2);

    padding: 16px;
    box-sizing: border-box;

    .popup__popup-add-ad {
      .popup-add-ad__btns .popup-add-ad__btn-buy,
      .popup-add-ad__btns .popup-add-ad__btn-sell {
        width: 100%;
      }

      .popup-add-ad__header-text p {
        font-size: 20px;
      }
    }
  }
}

/*---------------------------------------------------*/

/*---------------------------------------------------*/
/*--- auth.scss ---*/
@media screen and (max-width: 480px) {
  .popup__wrapper-auth.active {
    width: 300px;
    top: calc((100vh - 440px) / 2);
    left: calc((100vw - 300px) / 2);
  }

  .popup__wrapper-auth.active {
    padding: 16px;

    .popup__svg-close {
      top: 8px;
      right: 16px;
    }
  }

  .popup__wrapper-add-ad.active .popup__popup-message-for-guests .popup-message-for-guests__btns .popup-message-for-guests__btn-go-to-auth-reg {
    width: 100%;
  }
}

/*---------------------------------------------------*/

/*---------------------------------------------------*/

/*---------------------------------------------------*/

/*---------------------------------------------------*/
/*--- big-card-product.scss ---*/
@media screen and (max-width: 1024px) {
  .block-content .block-content__wrapper-header-poster {
    padding: 30px 16px 16px 16px;
    max-width: 100%;
    box-sizing: border-box;
  }

  .block-content .block-content__poster-block {
    flex-direction: column;
    padding: 16px;

    .poster-block__product-info {
      width: 100%;

      border: none;
      padding-right: 0;
    }

    .poster-block__feedback-info {
      width: 100%;

      padding-left: 0;
      padding-top: 26px;

      position: relative;
      height: calc(20px + 80px + 20px + 104px + 20px + 44px);

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      .poster-block__info-about-publisher,
      .poster-block__contacts,
      .poster-block__address {
        width: calc(50% - 15px);
      }

      .poster-block__contacts {
        margin-top: 0;

        .contacts__drop-down-menu {
          height: 40px;

          .contacts__svg-down.active,
          .contacts__svg-up.active {
            top: 10px;
          }
        }

        .contacts__contacts-info.active {
          height: 158px;
          box-sizing: border-box;
        }
      }

      .poster-block__address {
        position: absolute;
        top: calc(20px + 80px + 20px);
        margin: 0;
      }

      .poster-block__btn-send-letter-publisher {
        position: absolute;
        top: calc(20px + 80px + 20px + 104px + 20px);
        width: 100%;
        height: 44px;
        margin: 0;
      }
    }
  }

  .block-content .block-content__wrapper-others-posters .block-content__others-posters .block-content__one-poster {
    width: calc((100% - 60px) / 3);

    &:nth-child(4n+4) {
      margin-right: 30px;
    }

    &:nth-child(3n+3) {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .block-content .block-content__wrapper-others-posters .block-content__header-others-posters .block-content__show-all-posters {
    display: none;
  }

  .block-content .block-content__poster-block {
    padding: 16px 0;
    width: 100%;

    .poster-block__product-info {

      .poster-block__main-info-product {
        flex-direction: column;
        width: 100%;

        padding: 0 16px;
        box-sizing: border-box;

        .poster-block__images-carousel.active {
          width: 100%;

          .slider-images-product {
            width: 100%;

            .slick-list {
              width: 100%;
            }
          }

          .slider-nav {
            width: 100%;

            .slick-list {
              padding: 5px;
            }
          }
        }

        .poster-block__description-product {
          padding-left: 0;
          box-sizing: border-box;
          width: 100%;

          padding-top: 16px;
        }
      }

      .poster-block__btn-send-letter-publisher-xs {
        width: 100%;
        height: 40px;

        background: $poster-block__btn-send-letter-publisher_background;
        border-radius: 0;

        margin: 16px 0 0 0;

        //@extend %row-flex;
        display: flex;
        justify-content: center;
        align-items: center;

        position: static;

        display: none;

        p {
          @include text_Roboto(13px, $poster-block__btn-send-letter-publisher_text-color);
          font-weight: bold;
          line-height: normal;

          text-align: center;
          letter-spacing: 0.03em;
          text-transform: uppercase;
        }
      }

      .poster-block__secondary-info {
        flex-wrap: nowrap;
        flex-direction: column;
        width: 100%;

        padding: 0 16px;
        box-sizing: border-box;

        .poster-block__characteristics {
          width: 100%;
          margin-top: 16px;
          margin-right: 0;
        }
      }
    }

    .poster-block__feedback-info {
      position: static;
      height: auto;
      justify-content: flex-start;
      flex-direction: column;

      padding: 0 16px;
      box-sizing: border-box;

      .poster-block__info-about-publisher,
      .poster-block__contacts,
      .poster-block__address {
        width: 100%;
        margin-top: 16px;
      }

      .poster-block__address {
        position: static;
        margin-top: 0;
      }

      .poster-block__btn-send-letter-publisher {
        display: none;
      }
    }
  }

  .block-content .block-content__wrapper-others-posters {
    padding: 0 16px 16px 16px;
    box-sizing: border-box;

    .block-content__others-posters .block-content__one-poster {
      width: 100%;
      margin-right: 0;

      &:nth-child(4n+4) {
        margin-right: 0;
      }
    }

    .block-content__header-others-posters .block-content__header-others-posters {
      font-size: 18px;
    }

    .block-content__show-all-posters-xs {
      //@extend %row-flex;
      display: flex;
      justify-content: center;

      width: 100%;
      height: 40px;
      background: $show-all-posters_background;
      border-radius: 6px;

      padding: 0 15px;
      box-sizing: border-box;

      cursor: pointer;

      .show-all-posters__text {
        @include text_Roboto(13px, $show-all-posters__text_text-color);
        font-weight: bold;
        line-height: 40px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
      }

      .show-all-posters__svg-arrow {
        margin-left: 10px;

        svg {
          padding: 12px 0;
        }
      }
    }
  }

  .block-content .block-content__wrapper-header-poster {
    padding: 50px 0 0 0;
  }

  .block-content .block-content__poster-block .poster-block__feedback-info .poster-block__btn-send-letter-publisher-xs {
    border-radius: 0;
    margin: 0;
  }
}

/*---------------------------------------------------*/

/*---------------------------------------------------*/
/*--- classifieds-filter.scss ---*/
@media screen and (max-width: 1178px) {
  .block-content .block-content__wrapper-classifieds-filter-and-results {
    padding: 26px 16px;
  }
  .block-content .block-content__wrapper-header-classifieds-filter .block-content__header-classifieds-filter {
    padding: 0 16px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 1024px) {
  .block-content .block-content__wrapper-classifieds-filter-and-results {

    .block-content__wrapper-filter-results .block-content__posters .block-content__one-poster {
      width: calc((100% - 30px) / 2);

      &:nth-child(3n+3) {
        margin-right: 30px;
      }

      &:nth-child(2n+2) {
        margin-right: 0;
      }
    }
  }

  .block-content .block-content__wrapper-header-classifieds-filter {
    box-sizing: border-box;
    padding: 0 0;
  }

  .block-content .block-content__wrapper-header-classifieds-filter .block-content__header-classifieds-filter {

    justify-content: space-between;

    .header-classifieds-filter__btn-go-back,
    .header-classifieds-filter__bread-crumbs,
    .header-classifieds-filter__btns-classifieds-filter {
      width: auto;
      padding: 0 5px;
    }

    .header-classifieds-filter__btns-classifieds-filter {
      .header-classifieds-filter__btn-new-classifieds,
      .header-classifieds-filter__btn-lowest-price,
      .header-classifieds-filter__btn-highest-price {
        text-align: center;

        p {
          padding-left: 15px;
          padding-right: 0;
        }
      }
    }

  }
}

@media screen and (max-width: 768px) {


  .block-content .block-content__wrapper-header-classifieds-filter .block-content__header-classifieds-filter,
  .block-content .block-content__wrapper-classifieds-filter,
  .block-content .block-content__wrapper-filter-results {
    display: none;
  }

  .block-content__header-classified-type {
    width: 100%;
    box-sizing: border-box;
    clear: both;
    background: #fff;
    padding: 0 16px;
    border-bottom: 2px solid lightgrey;
  }

  .block-content__classifieds-filter-block-xs {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 40px;

    .block-content__header-drop-down-menu-sort,
    .block-content__header-drop-down-menu-filter {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 16px;
      //width: 50%;
      width: 100%;
      height: 100%;
      background: $block-content__header-drop-down-menu_background;
      box-sizing: border-box;

      p {
        padding-left: 15px;
      }

      .header-drop-down-menu-sort__svg-active,
      .header-drop-down-menu-filter__svg-active {
        display: none;
      }

      .header-drop-down-menu-sort__svg-noactive,
      .header-drop-down-menu-filter__svg-noactive {
        display: block;
      }

      .header-drop-won-menu-filter__arrow {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        border-color: #ffaa00 transparent transparent transparent;
        margin-left: 10px;

        &_green {
          border-color: #21c700 transparent transparent transparent;;
        }
      }

      p {
        @include text_Roboto(16px, $block-content__header-drop-down-menu_text-color);
        line-height: 24px;
      }

      &.active {
        background: $block-content__header-drop-down-menu_active_back;

        p {
          color: $block-content__header-drop-down-menu_active_text-color;
        }

        .header-drop-down-menu-sort__svg-noactive,
        .header-drop-down-menu-filter__svg-noactive {
          display: none;
        }

        .header-drop-down-menu-sort__svg-active,
        .header-drop-down-menu-filter__svg-active {
          display: block;
        }

        .header-drop-won-menu-filter__arrow {
          border-color: #fff transparent transparent transparent;
          transform: rotate(-180deg);
        }

      }
    }

    .block-content__header-drop-down-menu-sort {
      border-right: 2px solid lightgrey;
      box-sizing: border-box;
    }


    .block-content__drop-down-menu-sort {
      display: none;
    }

    .block-content__drop-down-menu-sort.active {
      display: block;
      position: absolute;
      top: 100%;
      width: 100%;
      background: $block-content__drop-down-menu-sort_active_back;
      height: auto;

      padding: 0 16px;
      box-sizing: border-box;

      z-index: 10;

      box-shadow: 3px 3px 10px $block-content__drop-down-menu-sort_active_bo-shadow;

      div {
        width: 100%;
        height: 48px;

        display: flex;
        justify-content: flex-start;
        align-items: center;

        p {
          @include text_Roboto(16px, $block-content__drop-down-menu-sort_active_text-color);
          line-height: 32px;
        }
      }
    }

  }

  .block-content__wrapper-classifieds-filter.active,
  .block-content .block-content__wrapper-filter-results.active {
    display: flex;
  }

  .block-content .block-content__wrapper-classifieds-filter-and-results {
    padding: 0;
    padding-bottom: 16px;

    .block-content__wrapper-classifieds-filter.active {
      flex-direction: column;

      width: 100%;
      padding: 0 16px 16px 16px;
      box-sizing: border-box;

      background: $block-content__wrapper-classifieds-filter_active_back;

      .block-content__filter-item-type-classifieds {
        display: flex;

        .block-content__filter-item-sale,
        .block-content__filter-item-purchase {
          width: 50%;

          input {
            margin: 0;
          }

          .filter-item-sale__checkbox-sale + label,
          .filter-item-purchase__checkbox-purchase + label {
            width: 100px;
          }
        }
      }
    }

    .block-content__wrapper-filter-results.active {
      padding: 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    }

    .block-content__wrapper-classifieds-filter {

      .menu-subcategories__amount-of-ads-found.active,
      .menu-delivery__amount-of-ads-found.active,
      .menu-region__amount-of-ads-found.active {
        display: none;
      }

      .block-content__wrapper-btn-filter-with-parametrs {
        justify-content: center;
      }
    }

    .block-content__wrapper-filter-results {
      width: 100%;

      .block-content__posters {
        width: 100%;

        .block-content__one-poster {
          width: 100%;
          margin-right: 0;

          &:nth-child(3n+3) {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .block-content__classifieds-filter-block-xs .block-content__header-drop-down-menu-sort p,
  .block-content__classifieds-filter-block-xs .block-content__header-drop-down-menu-filter p {
    font-size: 16px;
    line-height: normal;
    padding-left: 5px;
  }
}

@media screen and (max-width: 480px) {
  .block-content__classifieds-filter-block-xs .block-content__header-drop-down-menu-sort p,
  .block-content__classifieds-filter-block-xs .block-content__header-drop-down-menu-filter p {
    font-size: 15px;
  }
}

/*---------------------------------------------------*/

/*---------------------------------------------------*/
/*--- footer.scss ---*/
@media screen and (max-width: 1146px) {
  .footer .footer__wraper-footer {
    max-width: 100%;
    //padding: 16px 32px;
    padding: 16px;
    box-sizing: border-box;

    .footer__header-text {
      font-size: 13px;
    }

    .footer__element.last {
      height: 100%;
    }

    .footer__btn-add-poster {
      padding: 0 5px;
      box-sizing: border-box;
    }

    .footer__use-privacy {
      margin-top: 26px;
      flex-direction: column;

      .use-privacy__main-text {
        margin-right: 0;
        flex-wrap: wrap;
        justify-content: center;
      }

      .use-privacy__main-text,
      .use-privacy__secodary-text {
        margin: 0;
      }
    }

    .footer__contacts,
    .footer__element.last {
      height: 180px;
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 768px) {
  .footer .footer__wraper-footer {
    height: auto;

    padding: 0 15px;
    box-sizing: border-box;

    position: relative;

    .footer__contacts {
      width: 100%;
      height: auto;
      margin-right: 0;

      .contacts__link {
        line-height: 23px;
        letter-spacing: -0.03em;
      }
    }

    .footer__element {
      width: 50%;
      height: auto;

      margin-right: 0;
      margin-top: 26px;
    }

    .footer__element.last {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      position: static;

      .footer__btn-add-poster {
        padding: 0 10px;
        box-sizing: border-box;
        position: absolute;
        right: 15px;
        top: 15px;
        width: 140px;
        height: 50px;

        p {
          font-size: 12px;
        }
      }

      .footer__link-for-usaid {
        height: auto;
        width: auto;
      }

      .footer__wrapper-links-android-apple {
        display: flex;
        justify-content: space-between;
        height: 30px;
        width: auto;

        .footer__img,
        img {
          width: auto;
          height: 30px;
        }
      }
    }
  }

  .footer .btn-go-up {
    z-index: 9;
    left: calc(100vw - 60px);
  }
}

@media screen and (max-width: 480px) {
  .footer .footer__wraper-footer {

    .footer__header-text {
      margin-bottom: 12px;
    }

    .footer__contacts {
      padding-top: 16px;
    }
  }
}

/*---------------------------------------------------*/

/*---------------------------------------------------*/


/*---------------------------------------------------*/

/*---------------------------------------------------*/
/*--- popup-upload-application.scss ---*/
@media screen and (max-width: 1024px) {
  //body {
  //  overflow: hidden;
  //}

  .popup__wrapper-popup-upload-gp-as.active {
    flex-direction: column;
    height: 100vh;

    position: fixed;
    top: 0;
    left: 0;

    .popup-upload-gp-as__svg-close {
      position: fixed;
      top: 20px;
      right: 20px;
    }

    .popup__popup-upload-gp-as {
      flex-direction: column;
    }

    .popup-upload-gp-as__header-popup-upload-gp-as {
      width: 100%;

      .header-popup-upload-gp-as__logo {
        display: block;
      }

      .header-popup-upload-gp-as__text {
        margin-left: 0;
        margin-top: 20px;
      }
    }

    .popup-upload-gp-as__btns {
      width: 100%;

      .popup-upload-gp-as__btn-upload-googlePlay,
      .popup-upload-gp-as__btn-upload-appStore {
        width: 210px;
        height: 50px;
      }

      .popup-upload-gp-as__btn-upload-googlePlay {
        background-image: url("/img/google-play.png");
      }

      .popup-upload-gp-as__btn-upload-appStore {
        background-image: url("/img/app-store.png");
      }
    }

    .popup-upload-gp-as__btn-close {
      cursor: pointer;

      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 50px;

      p {
        @include text_Roboto(16px, $popup-upload-gp-as__btn-close_text-color);
        font-weight: normal;
        line-height: 26px;
        text-decoration-line: underline;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .popup__wrapper-popup-upload-gp-as.active {

    .popup-upload-gp-as__header-popup-upload-gp-as {
      flex-direction: column;

      .header-popup-upload-gp-as__text {
        margin-top: 20px;
        margin-left: 0;
      }
    }

    .popup-upload-gp-as__btns {
      flex-direction: column;

      margin-top: 20px;
      margin-left: 0;

      .popup-upload-gp-as__btn-upload-appStore {
        margin-top: 20px;
        margin-left: 0;
      }
    }
  }
}

/*---------------------------------------------------*/

/*---------------------------------------------------*/
/*--- search.scss ---*/
@media screen and (max-width: 1024px) {
  .block-content .block-content__best-posters .block-content__one-poster {
    width: calc((100% - 60px) / 3);

    &:nth-child(4n+4) {
      margin-right: 30px;
    }

    &:nth-child(3n+3) {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .block-content {

    .block-content__best-posters .block-content__one-poster {
      //width: calc((100% - 30px) / 2);

      width: 100%;
      margin-right: 0;

      &:nth-child(3n+3) {
        //margin-right: 30px;

        margin-right: 0;
      }

      &:nth-child(2n+2) {
        margin-right: 0;
      }

      .one-poster__about-product .one-poster__text-product {
        height: 60px;

        &:after {
          height: 60px;
        }
      }

    }

    .block-content__header-classifieds-filter {
      display: none;
    }

    .block-content__header-classifieds-filter-xs {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      width: 100%;

      //--- Выпадющие меню ---------------------------------
      .block-search-panel__menu-type-classifieds,
      .block-search-panel__menu-city {
        //@extend %column-flex;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        width: 50%;
        height: 40px;

        position: relative;

        background: rgba(0, 0, 0, 0.05);
        border: 1px solid $block-search-panel__menu_border-color;
        box-sizing: border-box;

        .menu-type-classifieds__item-menu,
        .menu-city__item-menu {
          position: relative;
          width: 100%;
          height: 100%;

          background: $block-search-panel__menu_item-menu_back;

          &:hover {
            //box-shadow: 0px 0px 3px gray;
          }

          select {
            width: 100%;
            height: 100%;
            padding: 0 15px;
            background: transparent;
            border: 0;
            appearance: none; // remove arrow
            -webkit-appearance: none; // remove arrow Chrome
            -moz-appearance: none; // remove arrow FF

            &::-ms-expand {
              display: none; // remove arrow IE
            }
          }

          .menu-type-classifieds__select-menu-type-classifieds,
          .menu-city__select-city {
            position: absolute;
            z-index: -9999;
            border: 8px solid transparent;
            border-bottom: 0;
            right: 15px;
            top: 0;

            &:nth-child(1) {
              top: 15px;
              border-top-color: $block-search-panel__menu_select_arrow;
              z-index: 1;
            }

            //&:nth-child(2) {
            //  top: 27px;
            //  border-top-color: $popup-add-violations__select-violation_two;
            //}
          }

          .menu-city__svg-marker {
            position: absolute;
            left: 15px;
            top: 10px;
            z-index: 1;
          }
        }

        #select-type-classifieds,
        #select-cities {
          width: 100%;
          height: 100%;

          border: none;
          padding: 0 30px 0 35px;
          box-sizing: border-box;

          @include text_Roboto(18px, $block-search-panel__menu_select_text-color);
          font-weight: 500;

          option {
            display: flex;

            width: 100%;
            height: 60px;

            @include text_Roboto(18px, $block-search-panel__menu_select_text-color);
            font-weight: 500;

            padding: 0 15px;
            box-sizing: border-box;
          }
        }

        #select-type-classifieds {
          padding: 0 30px 0 15px;
        }
      }

      //----------------------------------------------------
    }
  }
}

/*---------------------------------------------------*/

/*---------------------------------------------------*/
/*--- popup send message ---*/
@media screen and (max-width: 768px) {
  .popup__wrapper-send-message.active {
    width: 300px;
    //height: 470px;
    height: auto;

    top: calc((100vh - 470px) / 2);
    left: calc((100vw - 300px) / 2);

    padding: 16px;

    .popup__popup-send-message {

      padding-top: 35px;

      .popup-send-message__header .header-text {
        font-size: 16px;
      }

      .popup-send-message__to-whom .to-whom__text {
        font-size: 12px;
      }

      .popup-send-message__form-message .popup-send-message__textarea-message,
      .popup-send-message__form-message .popup-send-message__btn-send-message {
        width: 100%;
      }
    }
  }
}

/*----------------------Mobile-----------------------------*/

@media screen and (max-width: 375px) {
  .block-content .block-content__header-classifieds-filter-xs .block-search-panel__menu-city #select-cities {
    font-size: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .profile-edit__form {
    flex-direction: column;
    align-items: center;
  }

  .profile-edit__form-image {
    margin-bottom: 50px;
  }
}

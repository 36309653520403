.pt-80 {
  padding-top: 80px;
}

.pb-50 {
  padding-bottom: 50px;
}

.h2 {
  display: block;
  font-size: 1.6em;
}

.fl-r {
  float: right;
}
//@import "my-variables";
/*-------------------------------------*/
/*------ PopUp Add ad --------------------*/
.popup__wrapper-add-ad {
  display: none;
}
.popup__wrapper-add-ad.active {
  display: block;
  width: 400px;
  //height: 350px;
  height: auto;

  background: $popup__wrapper-add-ad_background;
  border-radius: 6px;

  z-index: 11;
  position: absolute;
  top: calc((100vh - 350px)/2);
  left: calc((100vw - 350px)/2);

  padding: 30px 40px;
  box-sizing: border-box;

  .popup__svg-close {
    position: absolute;
    float:right;

    top: 18px;
    right: 21px;

    width: 30px;
    height: 30px;
    border-radius: 50%;

    cursor: pointer;

    @extend %row-flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: $popup__add-ad_svg-close_hover_back;
    }
  }

  .popup__popup-message-for-guests {
    width: 100%;

    display: block;
    margin-top: 20px;

    .popup-message-for-guests__header-text {
      @extend %row-flex;
      justify-content: center;
      align-items: center;

      p {
        @include text_OpenSans( 22px, $popup-add-ad__header-text_color);
        font-weight: 500;
        line-height: normal;
        text-align: center;
      }
    }

    .popup-message-for-guests__btns {
      @extend %column-flex;
      justify-content: center;
      align-items: center;

      .popup-message-for-guests__btn-go-to-auth-reg {
        @extend %row-flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;

        width: 320px;
        height: 40px;

        margin-top: 40px;

        background: $popup-add-ad__btn_background-color;
        border-radius: 6px;

        p {
          @include text_Roboto( 13px, $popup-add-ad__btn_text-color);
          font-weight: bold;
          line-height: normal;

          text-align: center;
          letter-spacing: 0.03em;
          text-transform: uppercase;
        }
      }
    }
  }

  .popup__popup-add-ad {
    width: 100%;

    display: block;
    margin-top: 20px;

    .popup-add-ad__header-text {
      @extend %row-flex;
      justify-content: center;
      align-items: center;

      margin-top: 40px;

      p {
        @include text_OpenSans( 22px, $popup-add-ad__header-text_color);
        font-weight: 800;
        line-height: normal;
      }
    }

    .popup-add-ad__btns {
      @extend %column-flex;
      justify-content: center;
      align-items: center;

      .popup-add-ad__btn-buy,
      .popup-add-ad__btn-sell {
        @extend %row-flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;

        width: 320px;
        height: 40px;

        margin-top: 40px;

        background: $popup-add-ad__btn_background-color;
        border-radius: 6px;

        p {
          @include text_Roboto( 13px, $popup-add-ad__btn_text-color);
          font-weight: bold;
          line-height: normal;

          text-align: center;
          letter-spacing: 0.03em;
          text-transform: uppercase;
        }
      }
    }

  }
}
/*-----------------------------------------*/
/*--------- Page add-ad-buy ---------------*/
.block-content {
  height: auto;

  &__category-title {
    @media screen and (max-width: 768px) {
      margin: 0 auto;
      padding-top: 15px;
      padding-bottom: 0;
    }
  }
}

.block-content__add-ad-wrapper {
  padding-top: calc(40px + 78px);
  padding-bottom: 78px;
  width: 100%;
  max-width: 1146px;
  margin: 0 auto;

  height: auto;

  .block-content__add-ad-block {
    @extend %column-flex;
    justify-content: flex-start;
    align-items: center;
    background: $block-content__add-ad-block_back-color;

    width: 100%;
    height: auto;

    padding: 78px 0;

    .add-ad-block__header-text {
      @include text_OpenSans( 24px, $main-color-2);
      font-weight: 800;
      line-height: normal;
    }

    .add-ad-block__add-buy-ad-form,
    .add-ad-block__add-sell-ad-form {
      @extend %column-flex;
      justify-content: flex-start;
      align-items: center;
    }

    .add-ad-block__one-field {
      @extend %row-flex;
      justify-content: space-between;
      align-items: flex-start;

      width: 100%;
      height: auto;

      margin-top: 48px;

      &.invisible {
        display: none;
      }

      .one-field__text-field {
        @extend %row-flex;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        width: 170px;

        .one-field__name-field {
          @include text_Roboto( 14px, $add-ad-block_one-field__name-field_color-text);
          font-weight: normal;
          line-height: 48px;
        }

        .one-field__important-field {
          @include text_Roboto( 14px, $add-ad-block_one-field__important-field_color-text);
          font-weight: normal;
          line-height: 48px;

          padding-left: 5px;
        }
      }

      .one-field__value-field {
        width: 500px;
        //height: 48px;
        height: auto;
        min-height: 48px;

        @extend %row-flex;
        justify-content: space-between;
        align-items: flex-start;


        input {
          width: 100%;
          height: 48px;

          background: $add-ad-block_one-field__value-field_background;
          border: $add-ad-block_one-field__value-field_border;
          box-sizing: border-box;
          border-radius: 6px;

          &[placeholder]{
            @include text_Roboto( 15px, $add-ad-block_one-field__name-field_color-text);
            font-weight: 500;
            line-height: 23px;

            padding-left: 16px;
            box-sizing: border-box;
          }
        }

        textarea {
          border: none;
          width: 100%;
          height: 100px;
          resize: none;

          background: $add-ad-block_one-field__value-field_background;
          border: $add-ad-block_one-field__value-field_border;
          box-sizing: border-box;
          border-radius: 6px;

          &[placeholder]{
            @include text_Roboto( 15px, $add-ad-block_one-field__name-field_color-text);
            font-weight: 500;
            line-height: 23px;

            padding-left: 16px;
            box-sizing: border-box;
          }
        }

        .one-field__drop-down-menu {
          width: 100%;
          height: auto;
          position: relative;

          background: $add-ad-block_one-field__value-field_background;
          border: $add-ad-block_one-field__value-field_border;
          box-sizing: border-box;
          border-radius: 6px;
        }

        /*--- Випадаюче меню ---*/
        .one-field__menu-categories,
        .one-field__menu-categories_1,
        .one-field__menu-categories_2,
        .one-field__menu-payment-method,
        .one-field__menu-payment-method1,
        .one-field__menu-currency,
        .one-field__menu-weight-units,
        .one-field__menu-weight-units1,
        .one-field__menu-weight-units2,
        .one-field__menu-region,
        .one-field__menu-area,
        .one-field__menu-region1,
        .one-field__menu-city,
        .one-field__menu-organic,
        .one-field__menu-cooled,
        .one-field__menu-presence-gap {
          @extend %row-flex;
          justify-content: space-between;
          align-items: center;

          width: 100%;
          padding: 0 16px;
          box-sizing: border-box;

          height: 48px;

          cursor: pointer;

          p {
            @include text_OpenSans(16px,$main-color-2);
            font-weight: 800;
            line-height: normal;

            opacity: 0.5;
          }

          .block-content__svg-up,
          .block-content__svg-down { display: none;}

          .block-content__svg-up.active,
          .block-content__svg-down.active {
            @extend %row-flex;
            justify-content: center;
            align-items: center;

            width: 22px;
            height: 22px;

            &:hover {
              background: $main-color-1;
              border-radius: 100px;
            }
          }
        }

        .menu-categories__items-menu,
        .menu-categories__items-menu_1,
        .menu-categories__items-menu_2,
        .menu-payment-method__items-menu,
        .menu-payment-method__items-menu1,
        .menu-currency__items-menu,
        .menu-weight-units__items-menu,
        .menu-weight-units__items-menu1,
        .menu-region__items-menu,
        .menu-area__items-menu,
        .menu-city__items-menu,
        .menu-organic__items-menu,
        .menu-cooled__items-menu,
        .menu-presence-gap__items-menu { display: none;}

        .menu-categories__items-menu.active,
        .menu-categories__items-menu_1.active,
        .menu-categories__items-menu_2.active,
        .menu-payment-method__items-menu.active,
        .menu-payment-method__items-menu1.active,
        .menu-currency__items-menu.active,
        .menu-weight-units__items-menu.active,
        .menu-weight-units__items-menu1.active,
        .menu-region__items-menu.active,
        .menu-area__items-menu.active,
        .menu-city__items-menu.active,
        .menu-organic__items-menu.active,
        .menu-cooled__items-menu.active,
        .menu-presence-gap__items-menu.active {
          display: flex;

          @extend %column-flex;
          justify-content: flex-start;
          align-items: flex-start;

          width: 100%;
          height: auto;

          background: $add-ad-block_one-field__value-field_background;
          border: $add-ad-block_one-field__value-field_border;
          box-sizing: border-box;
          border-radius: 6px;

          z-index: 9;

          padding: 0 16px;
          box-sizing: border-box;

          .items-menu__name-item-menu {
            @include text_Roboto( 14px, $add-ad-block_one-field__name-field_color-text);
            font-weight: 500;
            line-height: 30px;
            opacity: 0.5;
            width: 100%;
            cursor: pointer;

            &:hover {
              background: darken(#fff, 10);
            }
          }
        }
        /*--------*/

        .menu-region__items-menu.active,
        .menu-area__items-menu.active,
        .menu-city__items-menu.active {
          height: 200px;
          overflow-y: auto;
        }

        .one-field__value-field_price-block,
        .one-field__quantity,
        .one-field__min-quantity {
          @extend %row-flex;
          justify-content: space-between;
          align-items: flex-start;

          width: 100%;
          height: auto;

          input {
            width: 200px;
            height: 48px;
          }

          .one-field__drop-down-menu {
            width: 100px;
          }

          .one-field__secondary-text {
            @include text_Roboto( 13px, $add-ad-block_one-field__name-field_color-text);
            font-weight: 500;
            line-height: 48px;
          }
        }

        .one-field__phone-code {
          width: 100px;
          height: 48px;

          @extend %row-flex;
          justify-content: center;
          align-items: center;

          @include text_Roboto( 14px, $add-ad-block_one-field__name-field_color-text);
          font-weight: 500;
          line-height: 30px;
        }

        .one-field__photos-ad {
          @extend %row-flex;
          justify-content: space-between;
          align-items: flex-start;

          width: 100%;

          .one-field__add-photos {
            @extend %row-flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            //.add-photos__btn-add-photo,
            //.one-field__image { display: none; }

            .add-photos__btn-add-photo,
            .one-field__image {
              @extend %row-flex;
              justify-content: center;
              align-items: center;

              width: 80px;
              height: 80px;

              background: $add-ad-block_one-field__value-field_background;
              border: $add-ad-block_one-field__value-field_border;
              box-sizing: border-box;
              border-radius: 6px;

              position: relative;

              //background-image: url("./img/add-photo.png");
              //background-position: center;
              //background-repeat: no-repeat;
              //background-size: cover;

              svg {
                position: absolute;
                top: 25px;
                left:25px;

                width: 30px;
                height: 30px;

                cursor: pointer;

                z-index: 0;
              }

              input {
                display: block;
                width: 100%;
                height: 100%;

                cursor: pointer;

                border: none;

                z-index: 10;
              }

              img {
                width: auto;
                height: auto;

                max-width: 100%;
                max-height: 100%;
              }

              .file_upload {
                display: block;
                position: relative;
                overflow: hidden;
                height: 100%;

                cursor: pointer;

                img {
                  width: 100%;
                  height: 100%;
                }

                .button,
                & > mark {
                  display: block;
                  cursor: pointer  ;
                }

                .button{
                  float: right;
                  box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  width: 8em;
                  height: 100%;
                  text-align: center  ;
                }

                & > mark{
                  background: transparent;
                  padding-left: 1em ;
                }

                input[type=file]{
                  position: absolute;
                  top: 0;
                  opacity: 0
                }
              }
            }

            .add-photos__wrapper-btn-add-photo {
              @extend %row-flex;
              justify-content: center;
              align-items: center;

              width: 100%;
              height: 100%;

              cursor: pointer;
            }

            .one-field__wrapper-images {
              @extend %row-flex;
              justify-content: flex-start;
              align-items: center;

              width: calc(500px - 80px * 2 - 50px);

              .one-field__image {
                margin-right: 25px;

                &:last-child {
                  margin-right: 0;
                }
              }
            }

          }
        }

        .one-field__payments-form {
          @extend %row-flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 50%;

          .one-field__radio {
            @extend %row-flex;
            justify-content: center;
            align-items: center;

            height: 48px;

            input {
              position: absolute;
              z-index: -1;
              opacity: 0;
              //margin: 10px 0 0 7px;
              width: 200px;
            }

            .radio__text {
              position: relative;
              padding: 0 0 0 35px;
              cursor: pointer;
            }

            .radio__text:before {
              content: '';
              position: absolute;
              top: -3px;
              left: 0;
              width: 22px;
              height: 22px;
              border: 1px solid $add-ad-block__one-field_radio_border-color;
              border-radius: 50%;
              background: $add-ad-block__one-field_radio_before_back;
            }

            .radio__text:after {
              content: '';
              position: absolute;
              top: 1px;
              left: 4px;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background:  $add-ad-block__one-field_radio_after_back;
              box-shadow: inset 0 1px 1px rgba(0,0,0,.5);
              opacity: 0;
              transition: .2s;
            }

            input:checked + .radio__text:after {
              opacity: 1;
            }

            input:focus + .radio__text:before {
              box-shadow: 0 0 0 3px rgba(255,255,0,.7);
            }

          }
        }
      }
    }

    .add-ad-block__btn-next-add-ad {
      width: 100%;
      height: 48px;

      border: none;

      background: $add-ad-block__btn-next-add-ad_back-color;
      border-radius: 6px;

      margin-top: 78px;

      cursor: pointer;

      p {
        @include text_Roboto( 13px, $main-color-1);
        font-weight: bold;
        line-height: 48px;

        text-align: center;
        letter-spacing: 0.03em;
        text-transform: uppercase;
      }
    }
  }
}

.block-content__add-ad-wrapper .block-content__add-ad-block .add-ad-block__add-buy-ad-form .col-sm-8.border-right {
  display: none;
}

.block-content__add-ad-wrapper .block-content__add-ad-block .add-ad-block__one-field .one-field__value-field .one-field__photos-ad .one-field__add-photos .add-photos__btn-add-photo input, .block-content__add-ad-wrapper .block-content__add-ad-block .add-ad-block__one-field .one-field__value-field .one-field__photos-ad .one-field__add-photos .one-field__image input {
  width: 80px;
}

.block-content__add-ad-wrapper .block-content__add-ad-block .add-ad-block__one-field .one-field__value-field .one-field__menu-categories {
  position: relative;
}

.block-content__add-ad-wrapper .block-content__add-ad-block .add-ad-block__one-field .one-field__value-field .one-field__menu-categories p {
  width: 100%;
  height: 44px;
  background: #FDFDFD;
  border: 2px solid transparent;
  box-sizing: border-box;
  border-radius: 6px;
  z-index: 1;
  line-height: 40px;
}

.block-content__add-ad-wrapper .block-content__add-ad-block .add-ad-block__one-field .one-field__value-field .hidden_input {
  display: none;
}

.block-content__add-ad-wrapper .block-content__add-ad-block .add-ad-block__one-field .one-field__value-field.error_validate,
.block-content__add-ad-wrapper .block-content__add-ad-block .add-ad-block__one-field .one-field__value-field .error_validate{
  border: 2px solid red!important;
  border-radius: 6px!important;
}

.one-field__menu-payment-method1{
  z-index: 1;
  position: relative;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  height: 48px;
  cursor: pointer;

 & > span {
   font-family: Open Sans;
   font-style: normal;
   font-size: 16px;
   color: #000000;
   font-weight: 800;
   line-height: normal;
   opacity: 0.5;
 }
}

.menu-payment-method__items-menu1 {
  background: #fff;
  display: block;
  position: absolute;
  border-right: 1px solid #D2D8DE;
  border-bottom: 1px solid #D2D8DE;
  border-left: 1px solid #D2D8DE;
  border-image: initial;
  left: 0;
  top: calc(100% - 2px);
  width: 100%;
  box-sizing: border-box;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  max-height: 140px;
  overflow-y: auto;

  & li {
    padding: 10px 15px;
    font-size: 14px;
    font-family: Roboto;
    opacity: 0.5;
    color: #000;
    cursor: pointer;

    &:hover, &:active {
      background: darken(#fff,10);
    }
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #787878;
    width: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.add-ad-block__details {
  position: absolute;
  font-weight: bold;
  display: block;
  width: 15px;
  height: 15px;
  text-align: center;
  line-height: 15px;
  background: #e2e1e1;
  border: 1px solid #000;
  border-radius: 50%;
  right: 20px;
  top: calc(50% - 10.5px);

  &:hover {
    background: #72d600;
    color: #fff;
    cursor: pointer;
  }
}

.block-header {
  .block-header__user-menu {
    .block-header__user-menu-second-part {
      .user-menu__item {
        cursor: pointer;
        padding-left: 17px;
        position: relative;

        .user-menu__item-content {
          cursor: pointer;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          line-height: normal;
          font-size: 14px;
          color: #000000;
          display: flex;
          align-items: center;
        }

        .user-menu__item-icon {
          vertical-align: text-bottom;
          margin-right: 4px;

          .user-menu__item-icon_flag {
            width: 22px;
            height: 20px;
          }
        }

        .user-menu__item-submenu {
          display: none;
          width: 140px;
          padding: 10px;
          @include text_Roboto(15px, #0075FF);
          position: absolute;
          background: white;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          z-index: 100;
          left: 0;

          .user-menu__item-submenu-item {
            padding: 5px;
            cursor: default;

            a {
              color: #0075FF;
              display: inline;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    .user-menu__item-border-left {
      border-left: 1px solid #EEEEEE;
    }

    .user-menu__item-padding19px {
      padding: 0 19px;
    }
  }
}

//@import "my-variables";
/*--- Footer ----------------------------------------------------------*/
.footer {
  width: 100%;
  height: 200px;

  background: $footer_background;

  margin-top: 16px;

  .footer__wraper-footer {
    //height: 36px;
    width: 100%;
    max-width: 1146px;

    margin: 26px auto 13px auto;

    @extend %row-flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    .footer__products {
      margin-right: 0;
    }

    .footer__header-text {
      @include text_Roboto( 17px, $main-color-2);
      font-weight: bold;
      line-height: normal;

      margin-bottom: 25px;
    }

    .footer__contacts {
      //width: calc((100% - 90px) / 4);
      width: calc((100% - 60px) / 3);

      @extend %column-flex;
      justify-content: flex-start;
      align-items: flex-start;

      margin-right: 30px;

      .footer__phones-administration,
      .contacts__mails-administration {
        @extend %column-flex;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .contacts__social-networks {
        @extend %row-flex;
        justify-content: flex-start;
        align-items: center;

        width: 100%;

        margin-top: 16px;

        .contacts__link-networks {
          display: block;
          width: auto;
          height: auto;

          cursor: pointer;

          margin-right: 30px;

          img {
            width: 30px;
            height: 30px;
          }
        }
      }

      .contacts__link {
        @include text_Roboto( 14px, $footer__link);
        font-weight: normal;
        line-height: 26px;

        cursor: pointer;
      }
    }

    .footer__use-privacy {
      @extend %row-flex;
      justify-content: center;
      align-items: center;

      width: 100%;

      margin-top: 55px;

      .use-privacy__main-text{
        @extend %row-flex;

        @include text_Roboto( 13px, $main-color-2);
        font-weight: normal;
        line-height: 20px;

        margin-right: 15px;

        a {
          @include text_Roboto( 13px, $main-color-2);
          font-weight: bold;
          line-height: 20px;

          text-decoration: none;

          cursor: pointer;

          margin-left: 5px;
        }
      }

      .use-privacy__secodary-text {
        @include text_Roboto( 13px, $use-privacy__secodary-text_text-color);
        font-weight: normal;
        line-height: 20px;
      }
    }

    .footer__element {
      //width: calc((100% - 90px) / 4);
      width: calc((100% - 60px) / 3);

      @extend %column-flex;
      justify-content: flex-start;
      align-items: flex-start;

      margin-right: 30px;

      .element__link {
        @include text_Roboto( 14px, $footer__link);
        font-weight: normal;
        line-height: 26px;

        cursor: pointer;
      }

      &:last-child,
      &.last {
        margin-right: 0;
        //height: 100%;
        height: 180px;
        justify-content: space-between;
        align-items: flex-end;

        a {
          display: block;
          width: auto;
          height: auto;

          cursor: pointer;
        }

        .footer__img,
        .footer__img img {
          width: auto;
          max-width: 100%;
          height: auto;
          max-height: 48px;
        }

        .footer__wrapper-links-android-apple {
          display: flex;
          justify-content: flex-start;
          height: 30px;
          //width: 100%;
          width: auto;

          a {
            margin-right: 30px;

            &:last-child {
              margin-right: 0;
            }
          }

          img {
            width: auto;
            height: 30px;
          }
        }

        .footer__link-for-usaid {
          height: auto;
          //width: 100%;
          width: auto;

          p {
            @include text_Roboto( 14px, $footer__link);
            font-weight: normal;
            line-height: 26px;
          }
        }
      }
    }

    .footer__btn-add-poster {
      width: 223px;
      //width: 100%;
      height: 40px;

      background: $footer__btn-add-poster_back;
      border-radius: 6px;

      @extend %row-flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;

      p {
        @include text_Roboto( 13px, $main-color-1);
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0.03em;
        text-transform: uppercase;

        padding-left: 10px;
        /* border-radius: 100px; */
      }
    }
  }

  .btn-go-up {
    position: fixed;
    bottom: calc(60px - 22px);
    right: 16px;

    @extend %row-flex;
    justify-content: center;
    align-items: center;

    width: 44px;
    height: 44px;
    background: $btn-go-up_back;

    border-radius: 50%;
    box-sizing: border-box;

    cursor: pointer;
    display: none;
  }
}
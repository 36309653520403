//@import "my-variables";

.block-content {
  .block-content__message-not-results {
    padding-top: 60px;
    padding-bottom: 30px;
    width: 100%;
    max-width: 1146px;
    margin: 0 auto;

    .message-not-results__text {
      @include text_Roboto(18px, $main-color-2);
      font-weight: 500;
    }
  }

  .block-content__best-posters {

    //display: flex;
    //flex-direction: row;
    //justify-content: flex-start;
    //align-items: center;
    //flex-wrap: wrap;
    //max-width: 1146px;
    //margin: 0 auto;
    //box-sizing: border-box;

    .block-content__one-poster {
      width: calc((100% - 90px) / 4);
      height: auto; //287px;
      background: $block-content__one-poster_back;
      border-radius: 16px;
      margin-right: 30px;
      margin-bottom: 30px;

      /*
      .one-poster__image {
        height: 170px;
        img {
          max-width: 100%;
          width: auto;
          max-height: 170px;
          min-height: 0;
          height: auto;
        }
      }
      */

      .one-poster__image-responsive-box {
        position: relative;
        width: 100%;

        border-top-left-radius: 16px;
        border-top-right-radius: 16px;

        border-bottom: 1px solid  $one-poster__image_border;

        &::before {
          content: "";
          display: block;
          padding-top: 100%; /* С помощью этого padding мы задаем высоту равную ширине блока */
        }

        .one-poster__image-content {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          //background: url(images/bg.jpg) no-repeat center center; /* Задаем фон, если требуется */
          background-size: cover; /* Растягиваем фон под размеры блока, если требуется */
          background-position: center;
          background-repeat: no-repeat;

          border-top-left-radius: 16px;
          border-top-right-radius: 16px;
        }
      }

      &.buy {
        .one-poster__image-responsive-box .one-poster__image-content {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          background-size: auto;
        }
      }

      .one-poster__about-product {
        width: 100%;
        height: auto;

        padding: 8px 16px 16px 16px;

        box-sizing: border-box;

        position: relative;
      }
      /*--- Fade text ---*/
      .one-poster__about-product .one-poster__text-product {
        width: 100%;
        position: relative;
        height: 80px;

        p {
          @include text_Roboto(14px, $main-color-2);
          //margin-bottom: 18px;

          font-weight: normal;
          line-height: 20px;

          margin: 0;

          overflow: hidden;

          &:first-of-type {
            //text-align: center;
          }
        }

        &:after {
          content: "";
          display: inline-block;
          position: absolute;
          height: 90px;
          width: 100%;
          top: 0;
          background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIvPjxzdG9wIG9mZnNldD0iMTIlIiBzdG9wLWNvbG9yPSIjZWVlZWVlIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZWVlZWVlIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
          background-size: 100%;
          background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #fff), color-stop(12%, #fff), color-stop(100%, rgba(238, 238, 238, 0)));
          background-image: -moz-linear-gradient(#fff, #fff 12%, rgba(238, 238, 238, 0));
          background-image: -webkit-linear-gradient(#fff, #fff 12%, rgba(238, 238, 238, 0));
          background-image: linear-gradient(#fff, #fff 12%, rgba(238, 238, 238, 0));
          pointer-events: none;
        }

        &:after {
          top: auto;
          bottom: 0;
          background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIgc3RvcC1vcGFjaXR5PSIwLjAiLz48c3RvcCBvZmZzZXQ9Ijg4JSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
          background-size: 100%;
          background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(238, 238, 238, 0)), color-stop(88%, #fff), color-stop(100%, #fff));
          background-image: -moz-linear-gradient(rgba(238, 238, 238, 0), #fff 88%, #fff);
          background-image: -webkit-linear-gradient(rgba(238, 238, 238, 0), #fff 88%, #fff);
          background-image: linear-gradient(rgba(238, 238, 238, 0), #fff 88%, #fff);
        }
        /*------*/
      }
      /*------*/
      .one-poster__about-product .one-poster__price-product {
        @include text_OpenSans(18px, $main-color-2);
        font-weight: bold;
        line-height: normal;
      }

      .one-poster__about-product .one-poster__btn-add-like {
        position: absolute;
        bottom: 16px;
        right: 16px;
      }

      &:nth-child(4n+4) {
        margin-right: 0;
      }
    }
  }

  .pagination {
    width: 100%;

    @extend %row-flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 30px;

    li:first-child,
    li:last-child {
      width: 30px;
      height: 30px;
      background: $main-color-1;
      border: 1px solid $pagination_li-active_color-border;

      box-sizing: border-box;
      border-radius: 3px;
    }

    li.active {
      width: 30px;
      height: 30px;
      //background: $main-color-1;
      //border: 1px solid $pagination_li-active_color-border;
      background: $pagination_li-active_color-border;

      box-sizing: border-box;
      border-radius: 3px;

      span {
        color: $main-color-1;
      }
    }

    li {
      @extend %row-flex;
      justify-content: center;
      align-items: center;

      width: 30px;
      height: 30px;
      background: none;

      border: 1px solid transparent;

      cursor: pointer;

      margin-right: 16px;

      span,
      a {
        @extend %link;

        @include text_Roboto( 13px, $main-color-2);

        font-weight: normal;
        line-height: 30px;
      }

      &:hover {
        border: 1px solid $pagination_li-active_color-border;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .block-content__header-classifieds-filter-xs {
    display: none;
  }
}

.burger-menu {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  border-bottom: 1px solid #A3A3A3;

  &__item {
    padding: 10px 0;
  }

  .padding-bottom20px {
    padding-bottom: 20px;
  }

  &__link{
    display: inline-flex;
    align-items: center;
    color: #000;
  }

  &__text {
    padding-left: 10px;
  }
}

.padding-top20px {
  padding-top: 20px;
}

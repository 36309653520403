//@import "my-variables";
/*-------------------------------------*/
.popup__overflow-send-message {
  display: none;
}

.popup__overflow-send-message.active {
  display: block;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: $popup__overflow_back;
}

.popup__wrapper-send-message {
  display: none;
}

.popup__wrapper-send-message.active {
  display: block;
  width: 638px;
  height: 440px;

  background: $popup__wrapper-send-message_background;
  border-radius: 16px;

  z-index: 11;
  position: absolute;
  top: calc((100vh - 440px)/2);
  left: calc((100vw - 638px)/2);

  padding: 45px;
  box-sizing: border-box;

  .popup__svg-close {
    position: absolute;
    float:right;

    top: 20px;
    right: 20px;

    width: 30px;
    height: 30px;
    border-radius: 50%;

    cursor: pointer;

    @extend %row-flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: $popup__wrapper-auth_active_hover_back;
    }
  }

  .popup__popup-send-message {
    width: 100%;

    display: block;

    .popup-send-message__header {
      .header-text {
        @include text_Roboto(24px, $main-color-2);
        font-weight: bold;
        line-height: normal;
      }
    }

    .popup-send-message__to-whom {
      @extend %row-flex;
      justify-content: flex-start;
      align-items: center;

      margin-top: 20px;

      .to-whom__text {
        @include text_Roboto(14px, $main-color-2);
        font-weight: normal;
        line-height: 20px;
      }
    }

    .popup-send-message__form-message {
      @extend %column-flex;
      justify-content: center;
      align-items: center;

      padding: 16px 0 0 0;

      .popup-send-message__textarea-message {
        width: 540px;
        height: 180px;

        background: $popup-send-message__textarea-message_back;
        border: 1px solid $popup-send-message__textarea-message_border;
        box-sizing: border-box;
        border-radius: 5px;

        &[placeholder] {
          @include text_Roboto(14px, $main-color-2);
          font-weight: normal;
          line-height: 20px;

          padding: 16px;
          box-sizing: border-box;
        }

        resize: none;
      }

      .popup-send-message__btn-send-message {
        @extend %row-flex;
        justify-content: center;
        align-items: center;

        width: 320px;
        height: 40px;

        border: none;

        background: $popup-send-message__btn-send-message_back;
        border-radius: 6px;

        margin-top: 30px;

        cursor: pointer;

        .btn-send-message__text {
          @include text_Roboto(13px, $main-color-1);
          font-weight: bold;
          line-height: normal;
          text-align: center;
          letter-spacing: 0.03em;
          text-transform: uppercase;
        }
      }
    }

  }
}

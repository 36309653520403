@mixin clearfix() {
  &:before, &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both
  }
}

@mixin text_Roboto($size, $color) {
  font-family: Roboto;
  font-style: normal;

  font-size: $size;
  color: $color;
}

.profile-view {
  border-radius: 16px;
  max-width: 1146px;
  min-height: 360px;
  background: white;
  padding: 30px;
  box-sizing: border-box;
  margin: 0 auto;
  @include clearfix();

  &__user{

    &-menu {
      @include text_Roboto(15px, #808080);

      &-wrap {
        float: left;
        display: inline-block;
        box-sizing: border-box;
        padding: 0 20px;
      }
    }

    &-photo {
      display: inline-block;
      height: 100%;

      &-wrap {
        width: 175px;
        height: 175px;
        text-align: center;
        overflow: hidden;
      }
    }

    &-controls {
      padding: 10px 0;

      &-item {
        padding: 10px 0;
      }

      &-link {
        @include text_Roboto(15px, #808080);
        display: inline;

        &.active {
          color: #0075FF;

          &:hover {
            text-decoration: underline;
          }
        }

        &:hover {
          color: #0075FF;
        }
      }

      &-edit {
        border: none;
        background: #F7F7F7;
        border-radius: 150px;
        padding: 7px 10px;
        cursor: pointer;
        outline: none;

        &:hover {
          &  span {
            color: #0075FF;
          }
        }

        & svg {
          vertical-align: text-bottom;
        }

        & span {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          line-height: normal;
          font-size: 15px;
          color: #000000;
        }
      }
    }

    &-information {

      &-wrap {
        float: left;
        display: inline-block;
        box-sizing: border-box;
        padding: 0 20px;
      }
    }

    &-name {
      font-weight: bold;
      @include text_Roboto(24px, #000);
      padding-bottom: 10px;
      display: block;
    }

    &-company {
      display: block;
    }

    &-data {
      padding: 10px 0 15px;
      display: block;

      & td {
        @include text_Roboto(15px, #000);
        min-width: 180px;
        padding-bottom: 10px;

        &:first-child {
          color: #808080;
        }
      }
    }
  }
}
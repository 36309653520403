.tablet-search {
  position: relative;
  width: 100%;
  height: 100%;
  background: white;
}

.tablet-search__wrapper {
  display: none;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  align-items: flex-start;
  height: 40px;
  position: relative;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid #CAE4E8;
  box-sizing: border-box;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .tablet-search__wrapper {
    display: block;
  }
}

.tablet-search__arrow {
  top: 15px;
  z-index: 1;
  right: 15px;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7.5px 0 7.5px;
  border-color: #21C700 transparent transparent transparent;
  pointer-events: none;
  margin-right: 10px;
}

.tablet-search__arrow-green {
  border-color: #21c700 transparent transparent transparent;
}

.tablet-search__icon-city {
  position: absolute;
  left: 15px;
  top: 10px;
  z-index: 1;
  pointer-events: none;
}

.tablet-search #SelectTabletCity {
  height: 100%;
  border: none;
  padding: 0 30px 0 35px;
  box-sizing: border-box;
  font-family: Roboto;
  font-style: normal;
  font-size: 15px;
  color: black;
  background: white;
  font-weight: 500;
  width: 220px;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.tablet-search #SelectTabletCity option {
  display: flex;
  width: 100%;
  height: 60px;
  font-family: Roboto;
  font-style: normal;
  font-size: 15px;
  color: black;
  font-weight: 500;
  padding: 0 15px;
  box-sizing: border-box;
}

.tablet-search #SelectTabletProposition {
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  font-family: Roboto;
  font-size: 16px;
  padding: 0 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.tablet-search #SelectTabletProposition option {
  display: flex;
  width: 100%;
  height: 60px;
  font-family: Roboto;
  font-style: normal;
  font-size: 15px;
  color: black;
  font-weight: 500;
  padding: 0 15px;
  box-sizing: border-box;
}

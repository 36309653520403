.block-header {
  .block-header__user-menu {
    .main-panel__logo {
      width: 100px;
      @extend %row-flex;
      align-items: center;

      img {
        max-width: 100%;
      }
    }
  }
}

/*--- COLORS ---*/
$main-color-1: #FFFFFF;
$main-color-2: #000000;
$main-color-3: #EEEEEE; /*borders*/
$main-color-4: #BABABA; /*block-search-panel__input-block text placeholder*/
$main-color-5: #CAE4E8; /*borders input in search-panel ; block-search-panel__menu-category border-color*/
$main-color-6: #A3A3A3; /*categories-panel__category-count text-color ; categories-panel__category-count text-color ; $menu-category__count-items-subcategory text-color, $best-posters__name-subblock-best-posters text-color*/
$main-color-7: #808080; /*header-poster__btn-go-back text-color ; header-poster__bread-crumb link-text-color ; header-poster__btn-add-favorites text-color ;  .use-privacy__secodary-text text-color; $header-poster__btn-go-back text-color ; $header-poster__bread-crumbs link-color ; $header-poster__btn-add-favorites text-color ; description-product__text-product text-color; footer link-color ; $use-privacy__secodary-text text-color*/
$main-color-8: #8F8F8F; /*description-product__date-poster text-color ; name-publisher__name-company text-color ; description-product__date-poster text-color ; $name-publisher__name-company text-color*/
$main-color-9: #21C700; /*contacts__drop-down-menu text-color ; $poster-block__btn-send-letter-publisher background ; contacts__drop-down-menu text-color*/
$main-color-10: #F7F7F7; /*block-header__wrapper-main-panel background-color ; $poster-block__characteristics background ; poster-block__contacts background ; poster-block__address background-color ; one-poster__image border-color*/
$main-color-11: #FFA800; /*main-panel__btn-add-poster background-color ; block-search-panel__btn-search background-color ;*/
$main-color-12: #86D900; /*block-header__wrapper-search-categories-panel backround-color1*/
$main-color-13: #21C700; /*block-header__wrapper-search-categories-panel backround-color2*/
$main-color-14: #D8F2F5; /*block-header__search-panel background-color*/
$main-color-15: #FDFDFD; /*block-search-panel__menu-category background-color*/
$main-color-16: #EDB900; /*block-search-panel__btn-search borders-color*/
$main-color-17: #DEDEDE; /*block-content background-color ; */
$main-color-18: #DADADA; /*$poster-block__images-carousel_sllick-list background-color*/
$main-color-19: #FFF9D7; /*info-about-publisher__img background*/
$main-color-20: #0075FF; /*show-all-posters__text text-color; $menu-category__name-subcategory text-color*/
$main-color-21: #D2D8DE;
$main-color-22: #7D7D7D; /* select arrow color*/
$main-color-23: rgba(174, 174, 186, 0.7);
$main-color-24: #EA9A00;
$main-color-25: #CDD1DA;
$main-color-26: #9FD468;
$main-color-27: #1EB700;
$main-color-28: #27EC00;
$main-color-29: rgba(0, 0, 0, 0.05);

/*-- Header ------------------------------------------------------*/
/*--- Первая строка хедера ---------------------------------------*/
$block-header__user-menu__borders-color: $main-color-3;
$block-header__user-menu__background: $main-color-1;
$block-header__user-menu__menu_background: $main-color-1;
$block-header__user-menu__submenu_background: $main-color-1;
/*--- Вторая строка хедера ---------------------------------------*/
$block-header__wrapper-main-panel_background: $main-color-10;
$main-menu__item_active: $main-color-1;
$main-panel__btn-add-poster_background: $main-color-11;
/*--- Блок поиска и категорий (хедер) ----------------------------*/
$block-header__wrapper-search-categories-panel_backround: radial-gradient(683.00px at 50% 44.19%, $main-color-12 0%, $main-color-13 100%);
$block-header__search-panel_background: $main-color-14;
$block-search-panel__menu-category_background: $main-color-15;
$block-search-panel__menu-category_border: $main-color-5;
/*--- Блок поиска и категорий (хедер) ----------------------------*/
$block-header__wrapper-search-panel_border: $main-color-5;
$block-search-panel__input-block_text-color: $main-color-4;
$block-search-panel__menu-city_background: $main-color-1;
$block-search-panel__menu-city_border: $main-color-5;
$block-search-panel__btn-search_background: $main-color-11;
$block-search-panel__btn-search_border: $main-color-16;
$block-header__wrapper-categories-panel_background: $main-color-1;
$categories-panel__category-count_text-color: $main-color-6;
$menu-category__name-subcategory_text-color: $main-color-20;
$menu-category__count-items-subcategory_text-color: $main-color-6;

$block-search-panel__menu_border-color: $main-color-5;
$block-search-panel__menu_back: $main-color-29;
$block-search-panel__menu_select_border: $main-color-22;
$block-content__one-poster_back: $main-color-1;
/*-----------------------------------------------------------------*/
/*--- header-adaptive ---*/
$block-header__wrapper-search-categories-panel_back: $main-color-10;
$block-header__main-panel_back: radial-gradient(160.00px at 50% 44.19%, $main-color-9 0%, $main-color-9 100%);
$user-menu__count-notifications_active_back: $main-color-11;
$user-menu__count-notifications_active_text-color: $main-color-1;

$burger__overflow_back: rgba(174, 174, 186, 0.7);
$main-panel__main-menu-burger_active_back: $main-color-1;
$main-panel__main-menu-burger_active_box-shadow: $main-color-6;
$block-header__user-menu_border: $main-color-6;

$user-menu__text_color: $main-color-2;
$user-menu__menu_back_color: $main-color-1;
$user-menu__menu_link_color: $main-color-2;
$user-menu__submenu_back: $main-color-1;

$block-search-panel__menu-type-classifieds_text-color: $main-color-2;
$menu-city__submenu_back: $main-color-1;
$menu-city__submenu_box-shadow: $main-color-14;

$block-search-panel__search-input-block_back: radial-gradient(160px at 50% 44.19%, $main-color-9 0%, $main-color-9 100%);
$block-search-panel__search-input-block_border-color: rgba(255, 255, 255, 0.2);

$block-header__btn-choose-categories_back: $main-color-11;
$btn-choose-categories__text_color: $main-color-1;
$block-header__wrapper-categories-panel-xs_back: $main-color-1;
$btn-back__text_color: $main-color-20;
$wrapper-categories-panel-xs__name-block_text-color: $main-color-2;
$categories-panel__menu-category_back: $main-color-1;

$menu-category__category-name_text-color: $main-color-2;

/*-- Content-block ---------------------------------------------------*/
/*--- Big Card Product -----------------------------------------------------*/
$block-content_background: $main-color-10;
$block-content__header-poster_background: $main-color-1;
$header-poster__btn-go-back_border: $main-color-3;
$header-poster__btn-go-back_text-color: $main-color-7;
$header-poster__btn-go-back_text-color-hover: $main-color-20;
$header-poster__bread-crumbs_link-color: $main-color-7;
$header-poster__btn-add-favorites_border: $main-color-3;
$header-poster__btn-add-favorites_text-color: $main-color-7;

$block-content__poster-block_border: $main-color-3;
$poster-block__product-info_border: $main-color-3;

$poster-block__image-poster_background: $main-color-10;

$poster-block__images-carousel_sllick-list_background: $main-color-18;
$poster-block__images-carousel_slick-slide_img-border: $main-color-1;
$poster-block__images-carousel_slick-slick-arrow-background: $main-color-1;

$description-product__text-product_text-color: $main-color-7;
$description-product__date-poster_text-color: $main-color-8;

$characteristics-table__column-name-field_text-color: $main-color-7;
$characteristics-table__column-value-field_text-color: $main-color-2;

$poster-block__characteristics_background: $main-color-10;
$characteristics__info_background: $main-color-1;
$characteristics__value-characteristic_text-color: $main-color-2;

$poster-block__info-about-publisher_border: $main-color-3;
$info-about-publisher__img_background: $main-color-19;
$name-publisher__name-company_text-color: $main-color-8;
$poster-block__btn-send-letter-publisher_background: $main-color-9;
$poster-block__btn-send-letter-publisher_text-color: $main-color-1;

$poster-block__contacts_background: $main-color-10;
$contacts__drop-down-menu_text-color: $main-color-9;

$contacts__contacts-info_active_border: $main-color-3;
$linkColor: #0075FF;
$poster-block__address_background: $main-color-10;

$block-content__one-poster_background: $main-color-1;
$one-poster__image_border: $main-color-10;

$block-content__show-all-posters_back: $main-color-1;
$show-all-posters_text-color: $main-color-20;

$filter-item__checkbox_back: $main-color-1;
$filter-item__checkbox_border: $main-color-11;
$filter-item__checkbox_checked_back: $main-color-11;
$filter-item__checkbox_border: $main-color-24;

/*--- Pagination  -----------------------------------------------------*/
$pagination_li-active_color-border: $main-color-9;


/*--- Best Offers -----------------------------------------------------*/
$top-producers__one-producers_background: $main-color-1;
$top-producers__one-producers_border: $main-color-10;
$show-all-posters__text_text-color: $main-color-20;

$best-posters__name-subblock-best-posters_text-color: $main-color-6;

$show-all-posters_background: $main-color-1;

/*--- SEO ---*/
$seo-text__h1_color: #232323;
$seo-text__h2_color: #999999;
$seo-text__h3_color: #999999;
$seo-block__btn-read-more_text_color: #326BB5;

/*--- Filter Page ------------------------------------------------------*/
$header-classifieds-filter__btns-classifieds-filter_border: $main-color-3;
$header-classifieds-filter__btns-classifieds-filter_text-color: $main-color-2;
$header-classifieds-filter__btns-classifieds-filter_text-color-hover: $main-color-20;
$block-content__filter-item_border-color: $main-color-3;
$items-menu__name-item_text-color: $main-color-20;
$items-menu__count-posters_text-color: $main-color-6;
$block-content__btn-filter-with-parametrs_back: $main-color-9;

$block-content__header-drop-down-menu_background: $main-color-1;
$block-content__header-drop-down-menu_text-color: $main-color-2;

$block-content__header-drop-down-menu_active_back: $main-color-11;
$block-content__drop-down-menu-sort_active_back: $main-color-1;

$block-content__drop-down-menu-sort_active_bo-shadow: $main-color-6;
$block-content__header-drop-down-menu_active_text-color: $main-color-1;
$block-content__drop-down-menu-sort_active_text-color: $main-color-2;

$block-content__wrapper-classifieds-filter_active_back: $main-color-1;

$items-menu__name-item-menu_input_back: $main-color-1;
$items-menu__name-item-menu_input_border: $main-color-11;

$items-menu__name-item-menu_input_check_back: $main-color-11;
$items-menu__name-item-menu_input_check_border: $main-color-24;
$filter-item-value-added-tax__checkbox-sale_back: $main-color-25;
$filter-item-value-added-tax__checkbox-sale_border: $main-color-27;
$filter-item-value-added-tax__checkbox-sale_back_after: $main-color-28;
$filter-item-value-added-tax__checkbox-sale_checked_back_before: $main-color-9;

/*--- popUp-Auth ------------------------------------------------------*/
$popup__wrapper-auth_background: $main-color-1;
$popup-auth__tabs_text-color: $main-color-2;
$popup-auth__tabs_border-bottom: $main-color-10;

$popup__wrapper-auth_active_hover_back: $main-color-10;
$popup-auth__tab-sing_back: $main-color-10;
$popup-auth__tab-sing_active_back: $main-color-9;

$phone-block__phone-input_border: $main-color-21;
$phone-block__phone-code_border-color: $main-color-21;

$phone-block__phone-code_text-color: $main-color-7;
$phone-block__phone-input_back: $main-color-1;

$auth-block__pass-block_text-color: $main-color-2;
$auth-block__pass-input_border-color: $main-color-21;
$auth-block__svg_active_border-color: $main-color-21;
$auth-block__pass-input_back: $main-color-1;
$auth-block__pass-input_text-color: $main-color-7; //$main-color-2;

$auth-block__forgot-pass_text-color: $main-color-20;

$auth-block__btn-sing-in_back: $main-color-9;

$phone-block__phone-input_back: $main-color-1;
$phone-block__phone-input_border-color: $main-color-21;
$phone-block__phone-code_border-color: $main-color-21;
$phone-block__phone-code_text-color: $main-color-7;
$phone-block__phone-input_input_back: $main-color-1;

$sms-code-block__sms-code-input_input_back: $main-color-15;
$sms-code-block__sms-code-input_input_border-color: $main-color-21;

$sms-code-block__sms-code-input_text-color: $main-color-7;
$sms-code-block__btn-get-sms_back: $main-color-11;
$reg-block__btn-next-step_back: $main-color-13;
$reg-block__name-block_input_back: $main-color-15;
$reg-block__name-block_input_border: $main-color-21;
$reg-block__name-block_input_text-color: $main-color-7;

$reg-block__repeat-pass-block_text-color: $main-color-7;
$reg-block__repeat-pass-input_border: $main-color-21;
$reg-block__repeat-pass-input_back: $main-color-1;
$reg-block__svg_border-color: $main-color-21;
$reg-block__btn-sing-up_back: $main-color-13;

$other-way-sing__wrapper-other-way-sing_text-color: $main-color-7;

$phone-block__phone-input_back: $main-color-1;
$phone-block__phone-input_border: $main-color-21;
$phone-block__phone-code_border: $main-color-21;
$phone-block__phone-code_text-color: $main-color-7;
$phone-block__phone-input_back: $main-color-1;

$sms-code-block__sms-code-input_back: $main-color-15;
$sms-code-block__sms-code-input_border: $main-color-21;
$sms-code-block__sms-code-input_text-color: $main-color-7;
$sms-code-block__btn-get-sms_back: $main-color-11;
$pass-reset__btn-next-step_back: $main-color-9;
$pass-reset__input_text-color: $main-color-7;

$pass-reset__back: $main-color-1;
$pass-reset__border: $main-color-21;
$pass-reset__svg_border: $main-color-21;
$pass-reset__svg_back: $main-color-1;
$pass-reset__btn-save_back: $main-color-9;
$error__btn-try-again_back: $main-color-9;

/*--- popUp-Add-ad ------------------------------------------------------*/
$popup__wrapper-add-ad_background: $main-color-1;
$popup-add-ad__header-text_color: $main-color-2;
$popup-add-ad__btn_background-color: $main-color-9;
$popup-add-ad__btn_text-color: $main-color-1;

$one-field__value-field_menu_border: $main-color-21;

/*--- Page Add-ad -------------------------------------------------------*/
$block-content__add-ad-block_back-color: $main-color-1;
$add-ad-block_one-field__value-field_background: $main-color-15;
$add-ad-block_one-field__value-field_border: 1px solid $main-color-21;
$add-ad-block_one-field__name-field_color-text: $main-color-2;
$add-ad-block_one-field__important-field_color-text: red;
$add-ad-block__btn-next-add-ad_back-color: $main-color-13;

$add-ad-block__one-field_radio_border-color: $main-color-25;
$add-ad-block__one-field_radio_before_back: $main-color-1;
$add-ad-block__one-field_radio_after_back: $main-color-26;

/*--- Footer ----------------------------------------------------------*/
$footer_background: $main-color-1;
$footer__link: $main-color-7;
$use-privacy__secodary-text_text-color: $main-color-7;
$footer__btn-add-poster_back: $main-color-11;

$btn-go-up_back: $main-color-18;

/*--- PopUp upload application ----------------------------------------*/
$popup__wrapper-popup-upload-gp-as_active_back: linear-gradient(0deg, $main-color-12 -14.67%, $main-color-9 81.33%);
$popup__wrapper-popup-upload-gp-as_active_text-color: $main-color-1;
$popup-upload-gp-as__btn-upload_back: $main-color-11;
$popup__add-ad_svg-close_hover_back: $main-color-10;

//adaptive
$popup-upload-gp-as__btn-close_text-color: $main-color-1;
$block-search-panel__menu_border-color: $main-color-5;
$block-search-panel__menu_item-menu_back: $main-color-1;
$block-search-panel__menu_select_arrow: $main-color-22;
$block-search-panel__menu_select_text-color: $main-color-2;
/*---------------------------------------------------------------------*/

$popup__overflow_back: $main-color-23;


/*--------------------------------------------------------------------*/
/*--- Popup Send Message ---*/
$popup-send-message__textarea-message_back: $main-color-15;
$popup-send-message__textarea-message_border: $main-color-21;
$popup-send-message__btn-send-message_back: $main-color-9;
$popup__wrapper-send-message_background: $main-color-1;
/*---------------------------------------------------------------------*/

/*--- Mixins fonts ---*/


/*-- Extend --*/

%row-flex {
  display: flex;
  flex-direction: row;
}

%column-flex {
  display: flex;
  flex-direction: column;
}

%link {
  text-decoration: none;
  cursor: pointer;
}

/*---------------------------------------------------------------------*/
a{
  @extend %link;
  display: block;
}



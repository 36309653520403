//@import "my-variables";
.block-content__show-all-posters-xs,
.block-content-top-producers__show-all-posters-xs {
  display: none;
}
.block-content {
  padding: 30px 8% 0;
  width: auto !important;
  .block-content__best-offers-header {
    padding-top: 41px;
    display: flex;
    justify-content: space-between;
    .block-content__best-offers-text {
      @include text_OpenSans( 24px, $main-color-2);
      font-weight: 800;
      line-height: normal;
    }
    //.block-content__show-all-posters {
    //  @extend %row-flex;
    //  justify-content: center;
    //  align-items: center;
    //
    //  width: 264px;
    //  height: 40px;
    //
    //  background: $main-color-1;
    //  border-radius: 6px;
    //
    //  cursor: pointer;
    //
    //  .show-all-posters__text {
    //    @include text_Roboto( 13px, $show-all-posters__text_text-color);
    //    font-weight: bold;
    //    line-height: normal;
    //    letter-spacing: 0.03em;
    //    text-transform: uppercase;
    //  }
    //
    //  .show-all-posters__svg-arrow {
    //    margin-left: 20px;
    //  }
    //}
  }
  .block-content__best-posters {
    padding: 26px 0 0 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    .block-content__best-posters-arrow-left {
      position: absolute;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      background: rgba(205, 205, 205, 0.5);
      top: 115px;
      left: -20px;
      cursor: pointer;
    }
    .block-content__best-posters-arrow-left:hover {
      background: rgba(255, 168, 0, 0.5);
    }
    .block-content__best-posters-arrow-right {
      position: absolute;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      background: rgba(205, 205, 205, 0.5);
      top: 115px;
      right: -20px;
      transform: rotate(180deg);
      cursor: pointer;
    }
    .block-content__best-posters-arrow-right:hover {
      background: rgba(255, 168, 0, 0.5);
    }
    .first-child {
      width: 24px;
      height: 1px;
      background: #ffffff;
      position: absolute;
      top: 27px;
      left: 16px;
    }
    .second-child {
      width: 9px;
      height: 1px;
      background: #ffffff;
      position: absolute;
      top: 23px;
      left: 16px;
      transform: rotate(135deg);
    }
    .third-child {
      width: 9px;
      height: 1px;
      background: #ffffff;
      position: absolute;
      top: 31px;
      left: 16px;
      transform: rotate(-135deg);
    }
    .best-posters__wrapper-are-selling {
      overflow: hidden;
      .best-posters__are-selling {
        @extend %row-flex;
        .block-content__one-poster {
          width: calc((100% - 60px) / 4);
          height: auto;
          background: $block-content__one-poster_background;
          border-radius: 16px;
          margin-right: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          //&:nth-child(3n+3) {
          //  margin-right: 0;
          //}
          /*
          .one-poster__image {
            @extend %row-flex;
            justify-content: center;
            align-items: center;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
            border-bottom: 1px solid  $one-poster__image_border;
            width: 100%;
            height: 178px;
            img {
              max-width: 100%;
              width: auto;
              max-height: 178px;
              min-height: 0;
              height: auto;
            }
          }
          */
          .one-poster__image-responsive-box {
            position: relative;
            width: 65%;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
            border-bottom: 1px solid  $one-poster__image_border;
            &::before {
              content: "";
              display: block;
              padding-top: 100%; /* С помощью этого padding мы задаем высоту равную ширине блока */
            }
            .one-poster__image-content {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              //background: url(images/bg.jpg) no-repeat center center; /* Задаем фон, если требуется */
              background-size: cover; /* Растягиваем фон под размеры блока, если требуется */
              background-position: center;
              background-repeat: no-repeat;
              border-top-left-radius: 16px;
              border-top-right-radius: 16px;
            }
          }
          .one-poster__about-product {
            width: 100%;
            height: auto;
            padding: 8px 16px 16px 16px;
            box-sizing: border-box;
            position: relative;
            /*--- Fade text -----------*/
            .one-poster__text-product {
              width: 100%;
              position: relative;
              height: 70px;
              overflow: hidden;
              p {
                @include text_Roboto( 14px, $main-color-2);
                font-weight: normal;
                line-height: 20px;
                margin: 0;
                overflow: hidden;
                &:first-of-type {
                  text-align: left;
                }
              }
              /*&:before,*/
              &:after {
                content: "";
                display: inline-block;
                position: absolute;
                height: 90px;
                width: 100%;
                top: 0;
                background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIvPjxzdG9wIG9mZnNldD0iMTIlIiBzdG9wLWNvbG9yPSIjZWVlZWVlIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZWVlZWVlIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
                background-size: 100%;
                background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #fff), color-stop(12%, #fff), color-stop(100%, rgba(238, 238, 238, 0)));
                background-image: -moz-linear-gradient(#fff, #fff 12%, rgba(238, 238, 238, 0));
                background-image: -webkit-linear-gradient(#fff, #fff 12%, rgba(238, 238, 238, 0));
                background-image: linear-gradient(#fff, #fff 12%, rgba(238, 238, 238, 0));
                pointer-events: none;
              }
              &:after {
                top: auto;
                bottom: 0;
                background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIgc3RvcC1vcGFjaXR5PSIwLjAiLz48c3RvcCBvZmZzZXQ9Ijg4JSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
                background-size: 100%;
                background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(238, 238, 238, 0)), color-stop(88%, #fff), color-stop(100%, #fff));
                background-image: -moz-linear-gradient(rgba(238, 238, 238, 0), #fff 88%, #fff);
                background-image: -webkit-linear-gradient(rgba(238, 238, 238, 0), #fff 88%, #fff);
                background-image: linear-gradient(rgba(238, 238, 238, 0), #fff 88%, #fff);
              }
            }
            /*-------------------------*/
            .one-poster__price-product {
              @include text_OpenSans( 18px, $main-color-2);
              font-weight: bold;
              line-height: normal;
            }
            .one-poster__btn-add-like {
              position: absolute;
              bottom: 16px;
              right: 16px;
            }
          }
        }
      }
    }
    .best-posters__wrapper-are-buying {
      width: calc((100% - 3 * 30px) / 4 + 30px);
      padding-left: 30px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      .best-posters__header-are-buying {
        @extend %row-flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        height: 40px;
        width: 100%;
        .block-content__show-all-posters {
          @extend %row-flex;
          justify-content: center;
          align-items: center;
          width: 200px;
          height: 40px;
          background: $main-color-1;
          border-radius: 6px;
          cursor: pointer;
          .show-all-posters__text {
            @include text_Roboto( 13px, $show-all-posters__text_text-color);
            font-weight: bold;
            line-height: normal;
            letter-spacing: 0.03em;
            text-transform: uppercase;
          }
          .show-all-posters__svg-arrow {
            margin-left: 10px;
          }
        }
      }
      .best-posters__are-buying {
        @extend %row-flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        .block-content__one-poster {
          width: 100%;
          height: auto;
          background: $block-content__one-poster_background;
          border-radius: 16px;
          margin-right: 0;
          .one-poster__image {
            @extend %row-flex;
            justify-content: center;
            align-items: center;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
            border-bottom: 1px solid  $one-poster__image_border;
            width: 100%;
            height: 90px;
            img {
              max-width: 100%;
              width: auto;
              max-height: 90px;
              min-height: 0;
              height: auto;
            }
          }
          .one-poster__about-product {
            width: 100%;
            height: auto;
            padding: 8px 16px 16px 16px;
            box-sizing: border-box;
            position: relative;
            /*--- Fade text -----------*/
            .one-poster__text-product {
              width: 100%;
              position: relative;
              height: 70px;
              overflow: hidden;
              p {
                @include text_Roboto( 14px, $main-color-2);
                font-weight: normal;
                line-height: 20px;
                margin: 0;
                overflow: hidden;
                &:first-of-type {
                  text-align: left;
                }
              }
              /*&:before,*/
              &:after {
                content: "";
                display: inline-block;
                position: absolute;
                height: 90px;
                width: 100%;
                top: 0;
                background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIvPjxzdG9wIG9mZnNldD0iMTIlIiBzdG9wLWNvbG9yPSIjZWVlZWVlIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZWVlZWVlIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
                background-size: 100%;
                background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #fff), color-stop(12%, #fff), color-stop(100%, rgba(238, 238, 238, 0)));
                background-image: -moz-linear-gradient(#fff, #fff 12%, rgba(238, 238, 238, 0));
                background-image: -webkit-linear-gradient(#fff, #fff 12%, rgba(238, 238, 238, 0));
                background-image: linear-gradient(#fff, #fff 12%, rgba(238, 238, 238, 0));
                pointer-events: none;
              }
              &:after {
                top: auto;
                bottom: 0;
                background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIgc3RvcC1vcGFjaXR5PSIwLjAiLz48c3RvcCBvZmZzZXQ9Ijg4JSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
                background-size: 100%;
                background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(238, 238, 238, 0)), color-stop(88%, #fff), color-stop(100%, #fff));
                background-image: -moz-linear-gradient(rgba(238, 238, 238, 0), #fff 88%, #fff);
                background-image: -webkit-linear-gradient(rgba(238, 238, 238, 0), #fff 88%, #fff);
                background-image: linear-gradient(rgba(238, 238, 238, 0), #fff 88%, #fff);
              }
            }
            /*-------------------------*/
            .one-poster__price-product {
              @include text_OpenSans( 18px, $main-color-2);
              font-weight: bold;
              line-height: normal;
            }
            .one-poster__btn-add-like {
              position: absolute;
              bottom: 16px;
              right: 16px;
            }
          }
          &:nth-child(3n+3) {
            margin-right: 0;
          }
          &:nth-child(2n+2) {
            margin-right: 0;
          }
        }
      }
      .block-content__show-all-posters {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        //width: 200px;
        height: 40px;
        background: $main-color-1;
        border-radius: 6px;
        cursor: pointer;
        .show-all-posters__text {
          @include text_Roboto( 13px, $show-all-posters__text_text-color);
          font-weight: bold;
          line-height: normal;
          letter-spacing: 0.03em;
          text-transform: uppercase;
        }
        .show-all-posters__svg-arrow {
          margin-left: 20px;
        }
      }
      .best-posters__are-buying .block-content__one-poster {
        height: auto;
      }
    }
  }
  .best-posters__name-subblock-best-posters {
    @include text_OpenSans( 16px, $best-posters__name-subblock-best-posters_text-color);
    font-weight: bold;
    display: none;
  }
  .best-posters__header-are-selling {
    display: flex;
    align-items: flex-end;
    .block-content__show-all-posters {
      //background: $main-color-1;
      border-radius: 6px;
      cursor: pointer;
      display: flex;
      .show-all-posters__text {
        @include text_Roboto( 13px, $show-all-posters__text_text-color);
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0.03em;
        text-transform: uppercase;
      }
    }
  }
  .block-content__seo-block {
    width: 100%;
    max-width: 1146px;
    height: auto;
    margin: 26px auto;
    box-sizing: border-box;
    padding-bottom: 20px;
    .seo-block__wrapper-seo-text {
      width: 100%;
      height: 98px;
      overflow: hidden;
      h1 {
        @include text_Roboto (18px, $seo-text__h1_color);
        font-weight: bold;
      }
      h2 {
        @include text_Roboto (16px,  $seo-text__h2_color);
        text-align: justify;
        padding-top: 18px;
      }
      h3 {
        @include text_Roboto (14px,  $seo-text__h3_color);
        text-align: justify;
        padding-top: 16px;
      }
    }
    .seo-block__btn-read-more {
      float: right;
      width: auto;
      height: auto;
      cursor: pointer;
      .seo-block__btn-text {
        @include text_Roboto (14px,  $seo-block__btn-read-more_text_color);
      }
    }
  }
  .results-products__seo-text {
    padding-bottom: 20px;
    @include text_Roboto (18px, $seo-text__h1_color);
    font-weight: bold;
  }
  .block-content__seo-text-h1 {
    width: 100%;
    max-width: 1146px;
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: 20px;
    padding-bottom: 20px;
    @include text_Roboto (24px, $seo-text__h1_color);
    font-weight: bold;
  }
}
.block-content-top-producers {
  //display:none;
  width: 100%;
  background: $block-content_background;
  padding-top: 80px;
  .block-content-top-producers__top-producers-header {
    width: 100%;
    @extend %row-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1146px;
    margin: 0 auto;
    box-sizing: border-box;
    .block-content-top-producers__top-producers-text {
      @include text_OpenSans( 24px, $main-color-2);
      font-weight: 800;
      line-height: normal;
    }
    .block-content-top-producers__show-all-posters {
      @extend %row-flex;
      justify-content: center;
      align-items: center;
      //width: 200px;
      width: calc((100% - 3 * 30px) / 4);
      height: 40px;
      background: $main-color-1;
      border-radius: 6px;
      cursor: pointer;
      .show-all-posters__text {
        @include text_Roboto( 13px, $show-all-posters__text_text-color);
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0.03em;
        text-transform: uppercase;
      }
      .show-all-posters__svg-arrow {
        margin-left: 20px;
      }
    }
  }
  .block-content-top-producers__top-producers {
    @extend %row-flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1146px;
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: 20px;
    .top-producers__one-producers {
      display: flex;
      width: calc(100% - (100% - 60px) / 3);
      .top-producers__one-producer {
        width: calc((100% - 60px) / 2);
        /*height: 180px;*/
        height: 389px;
        background: $top-producers__one-producers_background;
        border: 1px solid $top-producers__one-producers_border;
        box-sizing: border-box;
        border-radius: 16px;
        margin-right: 30px;
        margin-bottom: 30px;
        .one-producer__image {
          @extend %row-flex;
          justify-content: center;
          align-items: center;
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;
          border-bottom: 1px solid  $one-poster__image_border;
          width: 100%;
          height: 299px;
          img {
            max-width: 100%;
            width: auto;
            max-height: 298px;
            min-height: 0;
            height: auto;
          }
        }
        .one-producer__about-producer {
          width: 100%;
          height: auto;
          padding: 8px 16px 16px 16px;
          box-sizing: border-box;
          position: relative;
          .one-producer__text-producer {
            width: 100%;
            position: relative;
            height: 70px;
            p {
              @include text_OpenSans(20px, $main-color-2);
              font-weight: normal;
              line-height: normal;
              opacity: 0.5;
              margin: 0;
            }
          }
        }
      }
    }
    .top-producers__two-producers {
      width: calc((100% - 60px) / 3);
      /*height: 180px;*/
      height: 389px;
      margin-right: 0;
      margin-bottom: 30px;
      @extend %column-flex;
      justify-content: space-between;
      align-items: flex-start;
      .top-producer__one-little-producer {
        width: 100%; /*362px;*/
        height: 180px;
        background: $top-producers__one-producers_background;
        border-radius: 16px;
        @extend %row-flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 100%;
          width: auto;
          max-height: 178px;
          min-height: 0;
          height: auto;
        }
      }
    }
  }
}
@mixin placeholderColor($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &::placeholder {
    color: $color;
  }
}

.profile-edit {
  max-width: 1146px;
  border-radius: 16px;
  background: #FFF;
  padding: 15px;
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;
  font-family: Roboto;
  font-size: 15px;

  &__form {
    display: flex;
    flex-flow: row nowrap;

    & input {
      outline: none;
    }

    &-wrapper {
      padding: 15px 0;
    }

    &-image {
      background: #FDFDFD;
      border: 1px solid #D2D8DE;
      box-sizing: border-box;
      border-radius: 6px;
      width: 180px;
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      flex-direction: column;
      position: relative;
      overflow: hidden;

      & [type=file] {
        visibility: hidden;
        width: 0;
        height: 0;
        position: absolute;
      }

      &-icon {
        width: 30px;
        height: 30px;

        &-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 50px;
          background: #21C700;
          border: 1px solid #21C700;
          box-sizing: border-box;
          border-radius: 6px;
        }
      }

      &-title {
        padding: 5px 10px;
        border-radius: 5px;
        visibility: hidden;
        color: #fff;
        font-weight: bold;
        opacity: 0;
        transition: all 0.3s;
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        bottom: 5px;
        margin: 0 5px;

        &.error {
          background: #e82222;
        }


        &.active {
          opacity: 1;
          visibility: visible;
        }
      }

      &-preview {
        top: 0;
        left: 0;
        width: 100%;
        position: absolute;
        height: 100%;
        z-index: 1;
      }

      &-preview {
        &-controls {
          z-index: 2;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          cursor: default;
          text-align: center;
          color: #fff;
          opacity: 0;
          font-size: 20px;
          background-color: rgba(0, 0, 0, 0.5);
          transition: opacity .3s;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            opacity: 1;
          }
        }

        &-delete, &-edit {
          cursor: pointer;
          margin: 0 15px;
        }
      }
    }


    &-image-wrapper, &-user-data {
      padding: 0 15px;
    }

    &-user-data {
      max-width: 650px;
      flex-grow: 1;
    }

    &-field {
      display: flex;
      flex-flow: row nowrap;
      padding-bottom: 30px;

      &-title {
        line-height: 45px;
        font-family: Roboto;
        font-size: 14px;
        color: #000000;
        position: relative;
        width: 150px;
        flex-shrink: 0;

        & span.required:after {
          content: '*';
          color: red;
          font-size: 18px;
        }
      }
    }

    &-input, &-select, &-textarea {
      max-width: 500px;
      width: 100%;
      outline: none;
      background: #FDFDFD;
      border: 1px solid #D2D8DE;
      box-sizing: border-box;
      border-radius: 6px;
      font-family: Roboto;
      font-weight: 500;
      line-height: 23px;
      font-size: 15px;
      color: #000000;
      padding: 10px 20px;
      height: 45px;

      @include placeholderColor(#BABBBB);

      &.invalid {
        border-color: #FF7878;
      }
    }

    &-select {
      -webkit-appearance: none;
      appearance: none;
      cursor: pointer;
      position: relative;

      &::-ms-expand {
        display: none;
      }

      &-custom {

        &-arrow {
          position: absolute;
          right: 15px;
          top: 15px;
        }

        &-menu {
          position: absolute;
          top: calc(100% - 4px);
          left: calc(0% - 1px);
          background-color: #FDFDFD;
          width: calc(100% + 2px);
          border: 1px solid #D2D8DE;
          box-sizing: border-box;
          border-radius: 0 0 6px 6px;
          overflow: hidden;
          display: none;
          max-height: 144px;
          overflow-y: auto;
          z-index: 2;

          & li {
            padding: 5px 10px;
            cursor: pointer;

            &:hover {
              background: #F2F1F1;
            }
          }
        }
      }
    }

    &-input-wrapper {
      flex-grow: 1;
      outline: none;
      position: relative;
      background: #FDFDFD;
      border: 1px solid #D2D8DE;
      box-sizing: border-box;
      border-radius: 6px;
      display: flex;

      &.invalid {
        border-color: #FF7878;
      }

      & input {
        border-radius: initial;
        border: none;
      }

      &_pass {
        max-width: 320px;

        & input {
          border-radius: 6px 0 0 6px;
        }
      }
    }

    &-phone-code {
      text-align: center;
      width: 90px;
      color: #808080;
      border-right: 1px solid #D2D8DE;
      z-index: 1;
      line-height: 45px;
      padding: 0 10px;

      & ~ input {
        border-left: none;
        border-radius: 0 6px 6px 0;
        flex-grow: 1;
      }
    }

    & input[name=workers] {
      width: 90px;
      position: relative;
      text-align: center;
    }

    &-textarea {
      font-weight: 500;
      line-height: 23px;
      padding: 10px 20px;
      color: #000000;
      resize: none;
      outline: none;
      border: 1px solid #D2D8DE;
      box-sizing: border-box;
      border-radius: 6px;
      height: 70px;
    }

    &-textarea {
      font-family: Roboto;
      font-weight: 500;
      line-height: 23px;
      font-size: 15px;
      padding: 10px 20px;
      color: #000000;
      //flex-grow: 1;
      resize: none;
      outline: none;
      border: 1px solid #D2D8DE;
      box-sizing: border-box;
      border-radius: 6px;

      &::-webkit-scrollbar {
        width: 4px;

        &-track {
          background-color: transparent;
        }

        &-thumb {
          background-color: #6C6C6C;
          border: 0.5em solid rgba(0, 0, 0, 0);
          background-clip: padding-box;
        }
      }
    }

    &-radio {
      display: inline-block;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #FFFFFF;
      border: 1px solid #FFA800;
      position: relative;
      vertical-align: middle;


      &:after {
        content: '';
        display: none;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #FFA800;
        position: absolute;
        left: calc(50% - 5px);
        top: calc(50% - 5px);
      }


      &-title {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        line-height: 20px;
        font-size: 14px;
        color: #000000;
      }

      &-wrapper {
        line-height: 45px;
        width: 100%;
        max-width: 500px;

        & label {
          cursor: pointer;
          display: inline-block;
          margin-right: 30px;
        }

        & input:checked ~ .profile-edit__form-radio:after {
          display: block;
        }
      }
    }

    &-block-title {
      width: 100%;
      line-height: 45px;
      color: #808080;
    }

    &-pass-eye {
      cursor: pointer;
      padding: 10px 15px;
      height: 100%;
      box-sizing: border-box;
      width: 60px;
      border-left: 1px solid #d2d8de;
      transition: color .3s ease-in;

      &.active path {
        fill: #66a4ee;
      }
    }

    & input[type=password] {
      color: #969A9F;
      font-size: 30px;
      letter-spacing: 2px;
    }

    & input[name=save] {
      background: #21C700;
      border-radius: 6px;
      height: 45px;
      width: 100%;
      border: none;
      cursor: pointer;
      transition: all 0.3s linear;
      text-transform: uppercase;
      font-size: 13px;
      text-align: center;
      color: #fff;
      letter-spacing: 0.03em;

      &:hover {
        background: #45d927;
      }
    }
  }
}



.block-content .block-content__error-page {
  padding-top: 60px;
  padding-bottom: 60px;
  width: 100%;
  max-width: 1146px;
  margin: 0 auto;

  .block-content__wrapper-error-page {
    height: calc(100% - (36px + 59px + 172px + 40px) - 278px);
    width: 100%;

    @extend %column-flex;
    justify-content: center;
    align-items: center;

    .error-pafe__img {
      width: 546px;
      height: 276px;
      margin: 0 auto;

      img {
        width: auto;
        height: auto;
        max-height: 100%;
        max-width: 100%;
      }
    }

    .error-page__wrapper-text {
      width: auto;
      height: auto;

      margin-top: 50px;

      .block-content__error-text {
        @include text_Roboto( 18px, $main-color-2);
      }
    }

    .error-page__btn-go-to-mainpage {
      @extend %row-flex;
      justify-content: center;
      align-items: center;

      width: auto;
      height: 30px;

      margin-top: 10px;

      cursor: pointer;

      .btn-go-to-mainpage__svg {
        @extend %row-flex;
        justify-content: center;
        align-items: center;

        width: auto;
        height: 100%;
      }

      .btn-go-to-mainpage__btn-text {
        @include text_Roboto( 18px, $main-color-20);
        padding-left: 5px;
      }
    }
  }
}


@media screen and (max-width: 768px) {
  .block-content .block-content__error-page {
    padding-top: 30px;
    max-width: 768px;

    .block-content__wrapper-error-page {
      height: auto;

      .error-pafe__img {
        width: 100%;
        height: auto;
        padding: 0 16px;
        box-sizing: border-box;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
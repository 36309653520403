.block-header {
  .block-header__user-menu {
    .block-header__user-menu-second-part {
      .main-panel__btn-add-poster {
        width: 220px;
        height: 40px;
        background: $main-panel__btn-add-poster_background;
        border-radius: 6px;
        @extend %row-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-right: 21px;

        p {
          @include text_Roboto(13px, $main-color-1);
          font-weight: bold;
          line-height: normal;
          letter-spacing: 0.03em;
          text-transform: uppercase;
          padding-left: 10px;
        }
      }
    }
  }
}

.block-header-mobile {
  display: none;
}

/*--- Header ----------------------------------------------------------*/
.block-header {
  width: 100%;

  /*--- Первая строка хедера ---------------------------------------*/
  .block-header__user-menu {
    padding: 0 8%;
    height: 59px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $block-header__user-menu__background;

    .block-header__user-menu-second-part {
      display: flex;
      align-items: center;
    }
  }
}

.padding-bottom20px {
  padding-bottom: 20px;
}

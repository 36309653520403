/*--- Вторая строка хедера (поиск)---------------------------------------*/
.block-header {
  .block-header__wrapper-search-categories-panel {
    height: 80px;
    background: $block-header__wrapper-search-categories-panel_backround;
    padding: 0 8%;

    .block-header__search-panel {
      padding-top: 20px;

      .block-header__block-search-panel {
        cursor: pointer;
        @extend %row-flex;
        align-items: center;
        width: 100%;

        .block-search-panel__menu-categories {
          min-width: 190px;
          height: 40px;
          background: #FFB800;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          cursor: pointer;

          .block-search-panel__menu-categories-view {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: normal;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #FFFFFF;
            margin-left: 10%;
          }

          .block-search-panel__menu-categories-arrow {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 6px solid #ffffff;
            margin-right: 6%;
          }

          .block-search-panel__menu-categories-body {
            position: absolute;
            z-index: 1000;
            top: 38px;
            left: 0;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
            border-radius: 6px;
            display: none;

            .block-header__categories-panel {
              @extend %row-flex;
              justify-content: space-between;
              align-items: center;
              flex-direction: column;

              a {
                @extend %row-flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                height: 80px;

                div {
                  padding-left: 10px;
                  padding-right: 10px;
                  box-sizing: border-box;

                  @extend %column-flex;
                  justify-content: flex-start;
                  align-items: flex-start;

                  .categories-panel__category-count {
                    @include text_Roboto(13px, $categories-panel__category-count_text-color);
                    font-weight: normal;
                    line-height: 20px;

                    margin: 0;
                  }

                  .categories-panel__category-name {
                    @include text_OpenSans(16px, $main-color-2);
                    font-weight: 800;
                    line-height: normal;

                    margin: 0;
                  }
                }
              }

              /*--- Выпадющее меню ---------------------------------*/
              ul {
                display: block;
                margin: 0;
                padding: 0;
                list-style: none;
                min-width: 190px;

                &:after {
                  display: block;
                  content: ' ';
                  clear: both;
                  float: none;
                }

                &.categories-panel__menu-category > li {

                  & > div.menu-category__link-menu {
                    @extend %row-flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    height: 80px;
                    color: black;
                    background-color: $block-header__user-menu__menu_background;
                    text-decoration: none;

                    a.menu-category__category-text {
                      padding-left: 10px;
                      padding-right: 10px;
                      box-sizing: border-box;

                      @extend %column-flex;
                      justify-content: center;
                      align-items: flex-start;

                      .menu-category__category-count {
                        @include text_Roboto(13px, $categories-panel__category-count_text-color);
                        font-weight: normal;
                        line-height: 20px;
                        margin: 0;
                      }

                      .menu-category__category-name {
                        @include text_OpenSans(16px, $main-color-2);
                        font-weight: 800;
                        line-height: normal;
                        margin: 0;
                      }
                    }

                    &:hover {
                      cursor: pointer;
                    }

                    .menu-category-arrow {
                      width: 0;
                      height: 0;
                      border-left: 5px solid transparent;
                      border-right: 5px solid transparent;
                      border-top: 6px solid #FFB800;
                      margin-left: 4px;
                      transform: rotate(270deg);
                    }
                  }
                }

                &.menu-category__submenu {
                  display: none;
                  position: absolute;
                  padding: 15px 25px;
                  box-sizing: border-box;
                  width: 100%;
                  top: 0;
                  left: 100%;
                  background-color: $block-header__user-menu__submenu_background;
                  border-radius: 0 0 6px 6px;
                  height: 420px;
                  overflow-x: hidden;
                  overflow-y: auto;
                  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

                  &::-webkit-scrollbar {
                    width: 7px;

                    &-track {
                      background-color: transparent;
                    }

                    &-thumb {
                      background-color: #CACACA;
                    }
                  }

                  & > li {
                    display: block;
                    padding-bottom: 15px;

                    & > a.menu-category__link-submenu {
                      display: block;
                      color: black;
                      background-color: $block-header__user-menu__submenu_background;
                      text-decoration: none;
                      height: auto;
                      box-sizing: border-box;
                      border-bottom-left-radius: 6px;
                      border-bottom-right-radius: 6px;

                      .menu-category__name-subcategory {
                        @include text_Roboto(15px, $menu-category__name-subcategory_text-color);
                        font-weight: normal;
                        line-height: 19px;
                        position: relative;
                        display: block;

                        &-counter {
                          @include text_Roboto(13px, $menu-category__count-items-subcategory_text-color);
                          font-weight: normal;
                          position: absolute;
                          right: 0;
                          bottom: 0;
                        }
                      }

                      &:hover {
                        text-decoration: underline;
                      }
                    }
                  }
                }

                &.categories-panel__menu-category > li:hover > ul.menu-category__submenu {
                  display: block;
                  z-index: 10 !important;
                }
              }
            }
          }
        }
      }

      .block-header__wrapper-search-panel {
        @extend %row-flex;
        justify-content: space-between;
        align-content: center;
        width: 100%;
        height: 40px;

        .block-search-panel__input-block {
          display: block;
          border: none;
          height: 40px;
          box-sizing: border-box;
          width: calc(100% - 21px - 240px - 52px);
          border-bottom: 1px solid $block-header__wrapper-search-panel_border;
          border-top: 1px solid $block-header__wrapper-search-panel_border;
          box-sizing: border-box;

          &[placeholder] {
            @include text_Roboto(15px, $block-search-panel__input-block_text-color);
            font-weight: normal;
            line-height: 23px;
            padding-left: 20px;
            box-sizing: border-box;
          }
        }

        .menu-city__link-menu {
          @extend %row-flex;
          justify-content: center;
          align-content: center;
        }

        /*--- Выпадющие меню ---------------------------------*/
        .block-search-panel__menu-type-classifieds,
        .block-search-panel__menu-city {
          @extend %column-flex;
          justify-content: flex-start;
          align-items: flex-start;
          width: 355px;
          height: 40px;
          position: relative;
          background: $block-search-panel__menu_back;
          border: 1px solid $block-search-panel__menu_border-color;
          box-sizing: border-box;

          .menu-type-classifieds__item-menu,
          .menu-city__item-menu {
            position: relative;
            width: 100%;
            height: 100%;
            background: white;

            select {
              width: 100%;
              height: 100%;
              padding: 0 15px;
              background: transparent;
              border: 0;
              appearance: none; /* remove arrow        */
              -webkit-appearance: none; /* remove arrow Chrome */
              -moz-appearance: none; /* remove arrow FF     */
              &::-ms-expand {
                display: none; /* remove arrow IE     */
              }
            }

            .menu-city__item-menu-arrow {
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 6px solid rgb(33, 199, 0);
              margin-left: 4px;
              position: absolute;
              top: 16px;
              right: 10px;
              pointer-events: none;
            }

            .menu-type-classifieds__select-menu-type-classifieds,
            .menu-city__select-city {
              pointer-events: none;
              position: absolute;
              z-index: -9999;
              border: 8px solid transparent;
              border-bottom: 0;
              right: 15px;
              top: 0;

              &:nth-child(1) {
                top: 15px;
                border-top-color: $block-search-panel__menu_select_border;
                z-index: 1;
              }
            }

            .menu-type-classifieds__item-menu-arrow {
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 6px solid rgb(33, 199, 0);
              margin-left: 4px;
              position: absolute;
              top: 16px;
              right: 10px;
              pointer-events: none;
            }

            .menu-city__svg-marker {
              position: absolute;
              left: 15px;
              top: 10px;
              z-index: 1;
              pointer-events: none;
            }
          }

          #select-type-classifieds,
          #select-cities {
            width: 100%;
            height: 100%;
            border: none;
            padding: 0 30px 0 35px;
            box-sizing: border-box;
            @include text_Roboto(15px, black);
            font-weight: 500;

            option {
              display: flex;
              width: 100%;
              height: 60px;

              @include text_Roboto(15px, black);
              font-weight: 500;
              padding: 0 15px;
              box-sizing: border-box;
            }
          }

          #select-type-classifieds {
            padding: 0 30px 0 15px;
          }
        }

        /*----------------------------------------------------*/

        .block-header__block-search-panel .block-search-panel__btn-search {
          @extend %row-flex;
          justify-content: center;
          align-items: center;
          min-width: 52px;
          height: 40px;
          background: $block-search-panel__btn-search_background;
          border: 1px solid $block-search-panel__btn-search_border;
          box-sizing: border-box;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }
}

.menu-category__submenu--right {
  left: 202%;
}


@media screen and (max-width: 1024px) {
  .block-content {
    padding-top: 25px;

    .block-content__best-offers-header {
      padding-top: 0;
      margin: 0;
    }
  }

  .block-content .block-content__category-title {
    margin: 0;
    padding-top: 20px;
  }

  .block-content .block-content__best-posters {
    display: none;
    //align-items: flex-start !important;
    //justify-content: flex-start;
    //margin: 0;
    //padding-top: 26px;

    .best-posters__wrapper-are-selling {
      //width: calc((100% / 3) * 2);
      width: calc(((100% - 2 * 20px) / 3) * 2 + 20px);
      height: auto;

      .best-posters__are-selling .block-content__one-poster {
        width: calc((100% - 20px) / 2);

        margin-right: 20px;
        margin-bottom: 20px;

        &:nth-child(3n+3) {
          margin-right: 20px;
        }

        &:nth-child(2n+2) {
          margin-right: 0;
        }
      }

      .best-posters__header-are-selling .block-content__show-all-posters {
        width: 202px;
      }
    }

    .best-posters__wrapper-are-buying {
      //width: calc(100% / 3);
      width: calc((100% - 2 * 20px) / 3 + 20px);
      padding-left: 20px;

      .best-posters__are-buying {
        width: 100%;

        .block-content__one-poster {
          width: 100%;
          height: auto;
          margin-right: 0;

          &:nth-child(3n+3) {
            margin-right: 0;
          }

          &:nth-child(2n+2) {
            margin-right: 0;
          }
        }
      }
    }
  }

  .block-content-top-producers {
    padding-top: 26px;

    .block-content-top-producers__top-producers {
      flex-direction: column;

      .top-producers__one-producers {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .top-producers__one-producer {
          width: calc((100% - 20px) / 2);
          margin-right: 20px;
          margin-bottom: 20px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .top-producers__two-producers {
        width: 100%;

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        height: auto;

        .top-producer__one-little-producer {
          width: calc((100% - 20px) / 2);
          margin-right: 20px;
          margin-bottom: 20px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .block-content-top-producers__show-all-posters-xs {
      display: none;
    }

    .block-content-top-producers__top-producers-header .block-content-top-producers__show-all-posters {
      width: calc((100% - 2 * 20px) / 3);
    }
  }
}

@media screen and (max-width: 768px) {

  .block-content .block-content__best-offers-header {
    margin-top: 0;
    padding-top: 26px;
  }

  .block-content .block-content__best-posters {
    display: block;
    height: auto;

    .best-posters__wrapper-are-selling,
    .best-posters__wrapper-are-buying {
      width: 100%;
    }

    .best-posters__wrapper-are-selling .best-posters__are-selling .block-content__one-poster {
      width: 100%;

      margin-right: 0;
    }

    .best-posters__wrapper-are-buying .best-posters__header-are-buying {
      margin-top: 26px;
    }

  }

  .block-content .block-content__best-posters .best-posters__wrapper-are-selling .best-posters__header-are-selling .block-content__show-all-posters,
  .block-content-top-producers .block-content-top-producers__top-producers-header .block-content-top-producers__show-all-posters {
    display: none;
  }

  .block-content .block-content__best-posters .best-posters__wrapper-are-buying .block-content__show-all-posters,
  .block-content__show-all-posters-xs,
  .block-content-top-producers__show-all-posters-xs {
    //@extend %row-flex;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    max-width: 100%;
    height: 40px;

    background: $show-all-posters_background;
    border-radius: 6px;

    cursor: pointer;

    .show-all-posters__text {
      @include text_Roboto(13px, $show-all-posters__text_text-color);
      font-weight: bold;
      line-height: normal;
      letter-spacing: 0.03em;
      text-transform: uppercase;
    }

    .show-all-posters__svg-arrow {
      margin-left: 20px;
    }
  }

  .block-content-top-producers {
    padding: 0 15px 15px 15px;
    box-sizing: border-box;

    .block-content-top-producers__top-producers-header {
      padding-top: 26px;
    }

    .block-content-top-producers__top-producers {
      flex-direction: column;
      padding: 0;

      .top-producers__one-producers {
        width: 100%;
        flex-direction: column;

        .top-producers__one-producer {
          width: 100%;
          margin-right: 0;
        }
      }

      .top-producers__two-producers {
        width: 100%;
        flex-direction: column;

        .top-producer__one-little-producer {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }

  .block-content .block-content__best-posters {
    .best-posters__wrapper-are-buying {
      padding: 0;
    }

    .best-posters__wrapper-are-selling {
      padding-top: 20px;

      .best-posters__are-selling .block-content__one-poster:nth-child(3n+3) {
        margin-right: 0;
      }
    }
  }

  .block-content__seo-block {
    padding: 0 15px 15px 15px;
    box-sizing: border-box;
  }
}
